import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import deDE from 'antd/lib/locale/de_DE';
import { ConfigProvider, theme } from "antd";
import ReactDOMClient  from "react-dom/client"
import "bootstrap/dist/css/bootstrap.min.css"

const { darkAlgorithm } = theme;

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<App/>);

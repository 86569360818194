// checkLogin.js
import { useNavigate } from 'react-router-dom';


export const useCheckLogin = () => {
  const navigate = useNavigate();
  console.log('Test', localStorage.getItem('token'));
  const checkLogin = () => {
    console.log('Test', localStorage.getItem('token'));
    if ( localStorage.getItem('token') === null) {
      navigate("/");
    }
  };

  return checkLogin;
};
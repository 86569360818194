import React, { useState , useEffect } from 'react';
import { Form, Input, Select, DatePicker,Tag, Button,Table, Pagination , Tooltip, Space, message, Divider, List, Avatar, Modal, Row, Col} from 'antd';
import { LeftOutlined,UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import serverConfig from '../config';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import 'moment/locale/de';
import dayjs from 'dayjs'

const { Option } = Select;

const Support = () => {
  const [supportfaelle, setSupportfaelle] = useState([]);

  const getUserRolle = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken.benutzerRollen)
      return decodedToken.benutzerRollen; 
    }
    return null;
  };
  const [filteredSupportfaelle, setFilteredSupportfaelle] = useState([]);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState(null); 
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  function onClick () {
    navigate('/supportfallErstellen');
  };

  useEffect(() => {
    
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleSupporterfaelle}`,  { 
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `${token}` : '',
    },
  })
      .then(response => response.json())
      .then(data => {
        setSupportfaelle(data);
        setFilteredSupportfaelle(data.slice(0, pageSize)); 
      })
      .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
  }, []); 
  const columns = [
   
    {
      title: 'Erstellt am',
      dataIndex: 'erstellt_am',
      key: 'erstellt_am',
      render: (erstellt_am) => moment(erstellt_am).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'offen' ? 'red' : status === 'in Bearbeitung' ? 'orange' : 'green'}>{status}</Tag>
      ),
    }, {
      title: 'Fallnummer',
      dataIndex: 'anzeigeFallNummer',
      key: 'anzeigeFallNummer',
    },
    {
      title: 'Kategorie',
      dataIndex: 'kategorie',
      key: 'kategorie',
    },
   
    {
      title: 'Grund',
      dataIndex: 'grund',
      key: 'grund',
      render: (text) => (
        <Tooltip title={text}>{text.length > 25 ? `${text.slice(0, 25)}...` : text}</Tooltip>
      ),
    },
    {
      title: 'Betroffene Benutzer*innen',
      dataIndex: 'betroffenebenutzersupportfalls',
      key: 'betroffenebenutzersupportfalls',
      render: (betroffenebenutzersupportfalls) => (
        <>
          {betroffenebenutzersupportfalls && betroffenebenutzersupportfalls.map((user) => (
            <Tag key={user.id} color="blue"  style={{ marginBottom: '4px'}}>
              {user.benutzer && user.benutzer.benutzername}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Bearbeiter*innen',
      dataIndex: 'bearbeitersupportfalls',
      key: 'bearbeitersupportfalls',
      render: (bearbeitersupportfalls) => {
        console.log(bearbeitersupportfalls);
        return (
          <>
            {bearbeitersupportfalls && bearbeitersupportfalls.map((user) => (
              <Tag key={user.benutzer.id} color="purple"  style={{ marginBottom: '4px'}}>
                {user.benutzer && user.benutzer.benutzername}
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: 'Melder*innen',
      dataIndex: 'meldersupportfalls',
      key: 'meldersupportfalls',
      render: (meldersupportfalls) => (
        <>
          {meldersupportfalls && meldersupportfalls.map((user) => (
            <Tag key={user.benutzer.id} color="default" style={{ marginBottom: '4px'}}>
              {user.benutzer && user.benutzer.benutzername}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Aktionen',
      key: 'actions',
      render: (text, record) => (
        <Row gutter={[8, 8]}>
        <Col> 
        <Link to={`/support/${record.id}/ansicht`}>
            <Button type="default" >
              Ansicht
            </Button>
            </Link>
            </Col>
            {(record.status !== 'geschlossen' || getUserRolle().includes('S-Admin') )&& ( 
        <Col>
        <Link to={`/support/${record.id}`}>
            <Button type="primary">
              Bearbeiten
            </Button>
            </Link>
            </Col>
             )}
      </Row>
      ),
    },
  ];
  const handleStatusChange = value => {
    // Setzen des Statusfilters basierend auf dem ausgewählten Wert oder null
    setStatusFilter(value !== null ? value : null);

  const filteredDataByStatus = supportfaelle.filter(item =>
    ((value === null || item.status.toLowerCase() === value.toLowerCase()))
  );

  // Filtern der Supportfälle basierend auf dem Suchbegriff im Benutzernamen
  const filteredData = filteredDataByStatus.filter(item =>
    item.betroffenebenutzersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase())) ||
    item.bearbeitersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase())) ||
    item.meldersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  // Berechnen der Gesamtanzahl der Seiten basierend auf der gefilterten Datensatzlänge
  const totalFilteredPages = Math.ceil(filteredData.length / pageSize);

  // Extrahieren der Datensätze für die erste Seite
  const newFilteredSupportfaelle = filteredData.slice(0, pageSize);

  // Aktualisieren der gefilterten Supportfälle und der Gesamtanzahl der Seiten
  setFilteredSupportfaelle(newFilteredSupportfaelle);
  setTotalPages(totalFilteredPages);
};

  useEffect(() => {
    const filteredDataByStatus = supportfaelle.filter(item =>
      ((statusFilter === null || item.status.toLowerCase() === statusFilter.toLowerCase()))
    );
    
    // Filtern der Supportfälle basierend auf dem Suchbegriff im Benutzernamen
    const filteredData = filteredDataByStatus.filter(item =>
      item.betroffenebenutzersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase())) ||
      item.bearbeitersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase())) ||
      item.meldersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    // Berechnen Sie die Gesamtanzahl der Seiten basierend auf den ungefilterten Supportfällen
    const totalInitialPages = Math.ceil(filteredData.length / pageSize);
    setTotalPages(totalInitialPages);
  
    // Initialisieren Sie die gefilterten Supportfälle für die erste Seite
    setFilteredSupportfaelle(filteredData.slice(0, pageSize));
  }, [searchTerm, supportfaelle]); 


  const handlePageChange = page => {
    setCurrentPage(page);
  
    // Berechnen des Startindex für die aktuellen Datensätze
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredDataByStatus = supportfaelle.filter(item =>
      ((statusFilter === null || item.status.toLowerCase() === statusFilter.toLowerCase()))
    );
    
    // Filtern der Supportfälle basierend auf dem Suchbegriff im Benutzernamen
    const filteredData = filteredDataByStatus.filter(item =>
      item.betroffenebenutzersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase())) ||
      item.bearbeitersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase())) ||
      item.meldersupportfalls.some(user => user.benutzer && user.benutzer.benutzername.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    // Gesamtanzahl der Seiten basierend auf der gefilterten Datensatzlänge berechnen
    const totalFilteredPages = Math.ceil(filteredData.length / pageSize);
  
    // Datensätze für die aktuelle Seite extrahieren
    const newFilteredSupportfaelle = filteredData.slice(startIndex, endIndex);
  
    // Update der gefilterten Fallakten für die aktuelle Seite
    setFilteredSupportfaelle(newFilteredSupportfaelle);
  
    // Update der Pagination mit der Gesamtanzahl der Seiten
    setTotalPages(totalFilteredPages);
  };
  

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex', color: 'white', marginTop: 50, width: '100%' }} >

    <Divider orientation="left" style={{ color: "white", borderColor: 'white', fontSize: 22, width: '100%'}} >
      Supportfälle
      </Divider>

    <Space.Compact block layout='horizontal'>
      <Form layout='horizontal' style={{ background: `transparent`, borderColor: '#8b00b8', color: 'white', width: '100%' }}
        labelCol={{
          span: 8,
        }}
        wrappercol={{
          span: 16,
        }}
        labelAlign='right'
      >
      
        
            <Input
              placeholder="Suche nach Benutzernamen"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ width: 500, marginBottom: 16 }}
              allowClear
            />
              <Select
                style={{ width: 200, marginBottom: 16, marginRight: 16 }}
                placeholder="Filtern nach Status"
                
                onChange={(e) => handleStatusChange(e)}
                value={statusFilter}
              >
                    <Option value={null}>Alle Status</Option>
                    <Option value="offen">offen</Option>
                    <Option value="in Bearbeitung">in Bearbeitung</Option>
                    <Option value="geschlossen">geschlossen</Option>
              </Select>
           
          <Form.Item>
              <Button type="primary" htmlType="submit" className='button' onClick={onClick} style={{ float: 'right' }}>Supportfall anlegen</Button>
            </Form.Item>

        <Row>
          <Col flex="auto">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalPages * pageSize}
              onChange={handlePageChange}
              style={{ marginTop: 16, textAlign: 'center', marginBottom: 16 }}
            />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
          
          <Table dataSource={filteredSupportfaelle} columns={columns} pagination={false}  rowKey={(record) => record.id} style={{marginBottom: '14px'}} />
          
          </Col>
        </Row>


      </Form>

  
    </Space.Compact>
  </Space>
  )}





export default Support;

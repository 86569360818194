import React from 'react'
import { Divider, Space } from 'antd'
import { authMiddleware } from './authMiddleware';




const Startseite = () => {
  

  return (
    
    
      <Space direction="vertical" size="middle" style={{ display: 'flex', color: 'white', marginTop: 50, width: '100%' }} >

        <Divider orientation="left" style={{ color: "white", borderColor: 'white', fontSize: 22, width: '100%'}} >Startseite</Divider>
        <Space.Compact block>

          {/* ToDo: Schwarzes Brett integrieren */}
          <p className='text-4xl'>Placeholder - Content Here</p> 
      </Space.Compact>
    </Space>
   
  )
}

export default Startseite


import React, { useState , useEffect } from 'react';
import { Form, Input, Select, DatePicker, Button, Space, message, Divider, List, Avatar, Modal } from 'antd';
import { LeftOutlined,UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import serverConfig from '../config';
import moment from 'moment';
import 'moment/locale/de';
import dayjs from 'dayjs'

const { Option } = Select;

const SupportfallErstellen = () => {
  
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  if(!token)
  {
    navigate('/');
  }
  const [form] = Form.useForm();
  const [bearbeiter, setBearbeiter] = useState([]);
  const [betroffeneUser, setBetroffeneUser] = useState([]);
  const [melder, setMelder] = useState([]); 
  const [benutzer, setBenutzer] = useState([]);
  const [grund, setGrund] = useState();
  const [status, setStatus] = useState();
  const [kategorie, setKategorie] = useState();
  const [supporter, setSupporter] = useState([]);
  const [datum, setDatum] = useState(
    moment().format('DD.MM.YYYY HH:mm')
  );
  const kategorien = [];
  kategorien.push(
      { value: 'Diskriminierung & Übergriff', label: 'Diskriminierung & Übergriff' },
      { value: 'Fail-RP', label: 'Fail-RP' },
      { value: 'Power-RP', label: 'Power-RP' },
      { value: 'RDM', label: 'RDM' },
      { value: 'VDM', label: 'VDM' },
      { value: 'Meta-Gaming', label: 'Meta-Gaming' },
      { value: '3rd Person Spotting', label: '3rd Person Spotting' },
      { value: 'Backseat-Gaming', label: 'Backseat-Gaming' },
      { value: 'Scripting', label: 'Scripting' },
      { value: 'Bug Using', label: 'Bug Using' },
      { value: 'Doppel Account', label: 'Doppel Account' },
      { value: 'Identitätsbetrug', label: 'Identitätsbetrug' },
      { value: 'Abwesenheit', label: 'Abwesenheit' },
      { value: 'Urlaub', label: 'Urlaub' },
      { value: 'Support Beobachtung', label: 'Support Beobachtung' }
  );
  const statuse = [];
  statuse.push(
    {
      value: 'offen',
      label: 'offen',
    },
    {
      value: 'in Bearbeitung',
      label: 'in Bearbeitung',
    },
    {
      value: 'geschlossen',
      label: 'geschlossen',
    },
  );
  useEffect(() => {
  fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleBenutzerNamen}`,  { 
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `${token}` : '',
    },
  })
      .then(response => response.json())
      .then(data => {
        setBenutzer(data);
      })
      .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
  }, []); 
  useEffect(() => {
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleSupporter}`,  { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
    })
        .then(response => response.json())
        .then(data => {
          setSupporter(data);
        })
        .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
    }, []); 

  const [supportGespraeche, setSupportGespraeche] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSubmit = async (values) => {
    const supportFall = {
      ...values,
      kategorie,
      status,
      grund,
      betroffeneUser,
      bearbeiter,
      melder,
      supportGespraeche,
    };

    try {
      const response = await fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.erstelleSupportfall}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `${token}` : '',
        },
        body: JSON.stringify(supportFall),
      });

      if (response.ok) {
        const data = await response.json();
        message.success('Supportakte erfolgreich erstellt!');
        form.resetFields();
        navigate('/support');
      } else {
        console.error('Fehler beim Aktualisieren der Datenbank:', response.status);
        message.error('Fehler beim Erstellen der Supportakte');
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Datenbank:', error);
      message.error('Fehler beim Erstellen der Supportakte');
    }
  };
  

  const handleAddGespraech = (gespraech) => {
    setSupportGespraeche([...supportGespraeche, gespraech]);
    setIsModalVisible(false);
  };

  const onBackLinkClick = () => {
    navigate(-1);
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex', color: 'white', marginTop: 50, width: '100%' }}>
      <Divider orientation="left" style={{ color: 'white', borderColor: 'white', fontSize: 22, width: '100%' }}>
        Supportfall erstellen
      </Divider>
      <Link to="#" onClick={onBackLinkClick} style={{ fontSize: '14px', color: '#fff', textDecorationLine: 'none' }}>
        <LeftOutlined style={{ fontSize: '14px', color: '#fff', verticalAlign: 'middle' }} /> zurück
      </Link>
      <Form
        form={form}
        style={{ background: `transparent`, width: '50%' }}
        labelCol={{
          span: 8,
        }}
        wrappercol={{
          span: 16,
        }}
        labelAlign="right"
      >
        <Form.Item label="Erstellt am" name="erstelltAm">
        <DatePicker showTime disabled defaultValue={dayjs(datum, 'DD.MM.YYYY HH:mm')} format={'DD.MM.YY HH:mm'} />
   
        </Form.Item>
        <Form.Item label="Kategorie" name="kategorie" rules={[{ required: true, message: 'Bitte wähle eine Kategorie aus!' }]}>
          <Select  allowClear  onChange={(selectedKategorie) => setKategorie(selectedKategorie)}
              options={kategorien}
          />     
         </Form.Item>

         <Form.Item label="Status" name="satatus" rules={[{ required: true, message: 'Bitte wähle einen Status aus!' }]}>
          <Select  allowClear  onChange={(selectedStatus) => setStatus(selectedStatus)}
              options={statuse}
          />     
         </Form.Item>

        <Form.Item label="Grund" name="grund" rules={[{ required: true, message: 'Bitte den Grund eingeben!' }]}>
          <Input.TextArea placeholder="Grund eingeben" rows={4} onChange={(event) => setGrund(event.target.value)}/>
        </Form.Item>

        <Form.Item label="Betroffene Benutzer*innen">
          <Select
            mode="multiple"
            placeholder="Betroffene Benutzer*innen auswählen"
            onChange={(selectedUser) => setBetroffeneUser(selectedUser)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
             {benutzer.map((user) => (
        <Option key={user.id} value={user.username}>
          {user.benutzername}
        </Option>
      ))}
          </Select>
        </Form.Item>
        <Form.Item label="Support*in">
          <Select
            mode="multiple"
            placeholder="Bearbeiter auswählen"
            onChange={(selectedBearbeiter) => setBearbeiter(selectedBearbeiter)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
             {supporter.map((user) => (
        <Option key={user.id} value={user.username}>
          {user.benutzername}
        </Option>
      ))}
          </Select>
        </Form.Item>
        <Form.Item label="Melder*in">
          <Select
            mode="multiple"
            placeholder="Melder auswählen"
            onChange={(selectedMelder) => setMelder(selectedMelder)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
             {benutzer.map((user) => (
        <Option key={user.id} value={user.username}>
          {user.benutzername}
        </Option>
      ))}
          </Select>
        </Form.Item>

       
      </Form>

      <Divider orientation="left" style={{ color: 'white', borderColor: 'white', fontSize: 22, width: '100%' }}>
        Supportgespräche
      </Divider>
      <SupportGespraecheList supportGespraeche={supportGespraeche} onAddGespraech={() => setIsModalVisible(true)} />
      <SupportGespraechModal
        isVisible={isModalVisible}
        onAddGespraech={handleAddGespraech}
        onCancel={() => setIsModalVisible(false)}
        benutzer={benutzer}
        supporter={supporter}
      />

<Button style={{ float: 'right' }} type="primary" onClick={() => handleSubmit()}>
  Supportakte erstellen
</Button>


    </Space>
  );
};

const SupportGespraecheList = ({ supportGespraeche, onAddGespraech }) => {
  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={supportGespraeche}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined  />} />}
              title={item.erstelltAm}
              description={
              
                <div> {console.log("supportGespraeche:", item)}
                  <div>{item.inhalt}</div>
                  <div>Anwesende Benutzer*innen: {item.benutzer.map((user) => user.label).join(', ')}</div>
                  <div>Supporter*innen: {item.supporter.map((user) => user.label).join(', ')}</div>
                </div>
              }
            />
          </List.Item>
        )}
      />
      <Button onClick={onAddGespraech}>Neues Gespräch hinzufügen</Button>
    </div>
  );
};

const SupportGespraechModal = ({ isVisible, onAddGespraech, onCancel, benutzer, supporter }) => {
  const [form] = Form.useForm();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedSupporter, setSelectedSupporter] = useState([]);
  const [gespraechDatum, setGespraechDatum] = useState(moment().format('DD.MM.YYYY HH:mm'));

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const gespraech = {
          erstelltAm: gespraechDatum,
          inhalt: values.inhalt,
          benutzer: selectedUsers,
          supporter: selectedSupporter,
        };
        onAddGespraech(gespraech);
        form.resetFields();
        setSelectedUsers([]);
        setSelectedSupporter([]);
        setGespraechDatum(moment().format('DD.MM.YYYY HH:mm'));
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
  };

  return (
    <Modal title="Neues Supportgespräch hinzufügen" visible={isVisible} onOk={handleOk} onCancel={onCancel} destroyOnClose>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Gesprächsdatum und -zeit"
          name="gespraechDatum"
        >
       
          <DatePicker
            showTime
            defaultValue={dayjs(gespraechDatum, 'DD.MM.YYYY HH:mm')} 
            format={'DD.MM.YY HH:mm'}
            onChange={(date) => setGespraechDatum(date.format('DD.MM.YYYY HH:mm'))}
          />
        </Form.Item>
        <Form.Item label="Gesprächsinhalt" name="inhalt" rules={[{ required: true, message: 'Bitte den Inhalt eingeben' }]}>
          <Input.TextArea placeholder="Protokoll eingeben" rows={4}/>
        </Form.Item>
        <Form.Item label="Anwesende Benutzer*innen">
          <Select
            mode="multiple"
            placeholder="Anwesende Benutzer*innnen auswählen"
            onChange={(selected) => setSelectedUsers(selected)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            labelInValue
          >
             {benutzer.map((user) => (
        <Option key={user.id} value={user.username}>
          {user.benutzername}
        </Option>
      ))}
          </Select>
        </Form.Item>
        <Form.Item label="Supporter*innen">
          <Select
            mode="multiple"
            placeholder="Supporter*innen auswählen"
            onChange={(selected) => setSelectedSupporter(selected)}
            labelInValue
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
             {supporter.map((user) => (
        <Option key={user.id} value={user.username}>
          {user.benutzername}
        </Option>
      ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SupportfallErstellen;

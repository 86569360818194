import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import deDE from 'antd/lib/locale/de_DE';
import Root from './components/Root';


const {  darkAlgorithm } = theme;

class App extends Component {
   
    state = {  } 
    render() { 
      
       
        return ( 
        
            <React.Fragment>
                <BrowserRouter>
                    <ConfigProvider locale={deDE}
                        theme={{  
                            algorithm: darkAlgorithm ,
                            token: {
                                    colorPrimary: '#fab9fc',//orange #ffa601 '8b008b
                            },
                        }}>   
                        <Root/>
                    </ConfigProvider>
                </BrowserRouter>
            </React.Fragment>);
            }
}

 
export default App;
import React, { useState, useEffect } from 'react';
import { Form, Input, Divider, Space, Row, Col, Pagination,Radio , Button, Table, Select, Modal, Checkbox , Tooltip, Typography } from 'antd';
import serverConfig from '../config';
import energyIcon from '../thunder.png';
import { Link } from 'react-router-dom';
import {  useNavigate  } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import '../index.css'
import { HourglassOutlined , CheckCircleOutlined , QuestionCircleOutlined , CloseCircleOutlined} from '@ant-design/icons';
//import { setAktiv }from './aktiv';
import { useCheckLogin } from './checkLogin';

const { Text } = Typography;
const Benutzer = () => {
  const token = localStorage.getItem('token');
  if(!token)
  {
    navigate('/');
  }

  const labelStyle = {
    textAlign: 'right',
    marginRight: '8px',
    fontWeight: 'bold',
    flexBasis: '150px', 
  };

  const contentStyle = {
    textAlign: 'left',
    marginLeft: '8px',
  };
  const labelStyleIcon = {
    marginRight: '8px',
    width: '24px',
    height: '24px',
  };

  const { Option } = Select;
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Banned', value: 'banned' },
        { text: 'Nicht gebannt', value: 'nichtGebannt' },
      ],
      onFilter: (value, record) => {
        if (value === 'banned') {
          return record.aktuellBanned === 1;
        } else if (value === 'nichtGebannt') {
          return record.aktuellBanned !== 1;
        }
        return true;
      },
      render: (text, record) =>  {
        let iconToShow;
        let contentToShow;
  
        if (record.aktuellBanned === 1) {
          iconToShow = <CloseCircleOutlined style={{ fontSize: '24px' , color: 'red' , alignItems: 'center'  }} />;
          if (record.bannedBis !== null) {
            const gebanntBis = new Date(record.bannedBis);
            if (gebanntBis.getFullYear() === 2030 && gebanntBis.getMonth() === 11 && gebanntBis.getDate() === 31) {
              contentToShow = 'Bis zur Klärung';
            } else {
              contentToShow = gebanntBis.toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              });
            }
          } else {
            contentToShow = 'Permabann';
          }
        } else {      
          iconToShow = <CheckCircleOutlined style={{ fontSize: '24px' , color: 'green' , alignItems: 'center' }} />;
        } 
    
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {contentToShow ? (
            <Tooltip title={`${contentToShow}`}> 
              {iconToShow}
            </Tooltip>
            ) : (
              iconToShow
            )}
          </div>
        );
      },
    },{
      title: 'Benutzernamen',
      dataIndex: 'benutzername',
      key: 'benutzername',
      render: (text, record) =>  (
        <>
          <a href="#" style={{ color: '#fff' , textDecoration: 'none' }} onClick={(e) => handleRowClick(e, record)}>{text}</a>
        </>
      ),
      align: 'left', // Links ausrichten
    },
      {
        title: 'Charakter',
        dataIndex: 'charakters',
        key: 'charakters',
        render: (charakters) => (
          <>
             {charakters.slice(0, 4).map((charakter, index) => (
               <div key={index} style={{ marginBottom: '0px' }}>
                <Tooltip title={`${charakter.vorname} ${charakter.nachname}`}>
              <a href="#" style={{ color: '#fff' , textDecoration: 'none' }} onClick={(e) => handleRowClickCharakter(e, charakter)}>  {charakter.vorname} {charakter.nachname}</a> 
              </Tooltip>
              </div>
              ))}
               {charakters.length > 4 && (
        <div style={{ marginBottom: '8px' }}>
          <Tooltip title="...">
            <span>...</span>
          </Tooltip>
        </div>
      )}
          </>
        ),
        align: 'left', // Links ausrichten
      },
    {
      title: 'Aktionen',
      key: 'actions',
      render: (text, record) => (
        
        <>
          <Button type="default" onClick={() => handleButtonClickInfos(record)}>
          Infos
        </Button>
        {' '}
        {record.aktuellBanned !== 1 && (
      <Button type="primary" onClick={() => handleButtonClick(record)}>
      Bannen
    </Button>
    )}
    
   {(record.aktuellBanned === 1 &&  record.bannedBis === '2030-12-30T23:00:00.000Z') && (
    <Button type="primary" onClick={() => handleUnbannedClick(record)}>
      entbannen
    </Button>
    )}
      {(record.aktuellBanned === 1 && record.bannedBis && record.bannedBis !== '2030-12-30T23:00:00.000Z') && (
      <Button type="primary" onClick={() => handleUnbannedClick(record)}>
        entbannen
      </Button>
    )}

    {(record.aktuellBanned === 1 && !record.bannedBis && getUserRolle().includes('S-Admin')) && (
      <Button type="primary" onClick={() => handleUnbannedClick(record)}>
       entbannen
      </Button>
    )}
    </>
      ),
      align: 'left',
    },
  ];
  const [supportfaelle, setSupportfaelle] = useState([]);
  const [selectedSupportfall, setSelectedSupportfall] = useState(null);
 
  const handleSupportfallChange = (value) => {
    setSelectedSupportfall(value);
  };
  const [selectedChar, setSelectedChar] = useState([]);

  const handleButtonClick = (record) => {
      
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleSupporterfaelleZuBenutzer}/${record.id}`,  { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
    })
        .then(response => response.json())
        .then(data => {
         
          const filteredSupportfaelle = data.filter(supportfall => supportfall.status === 'offen' || supportfall.status === 'in Bearbeitung');
          //console.log(filteredSupportfaelle);
          setSupportfaelle(filteredSupportfaelle);
        })
        .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
    setSelectedUser(record);
    setShowModalBannen(true);
  }
  const handleUnbannedClick = (record) => {
    // hier unbann
    const updatedData = {
      id: record.id,
      supporterId: setBenutzerId(),
      fall: selectedSupportfall,
    }
    console.log(setBenutzer());
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.entbannen}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
      body: JSON.stringify(updatedData), 
    })
      .then(response => response.json())
      .then(data => {
        console.log('Datenbank erfolgreich aktualisiert:', data);
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Datenbank:', error);
      });
      window.location.reload();
    
  }

  const handleRowClick = (e, record) => {
    e.preventDefault();
    setSelectedUser(record);
    setShowModal(true);
  };

  const handleRowClickCharakter = (e, charakter) => {
    e.preventDefault();
    
    e.preventDefault();

    const birthDate = new Date(charakter.geburtsdatum);
    const formattedBirthDate = birthDate.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });

    const charakterWithFormattedDate = { ...charakter, formatierterGebdat: formattedBirthDate };
    setSelectedChar(charakterWithFormattedDate);
    setShowModalChar(true);
  };

  useEffect(() => {
  }, [selectedChar]);

  
  const handleButtonClickInfos = (record) => {
    const userId = record.id;
    navigate(`/benutzerinfo/${userId}`);
  }


  //setAktiv();
  const [selectedUser, setSelectedUser] = useState([]);
  
  const [benutzer, setBenutzer] = useState([]);
  const [filteredBenutzer, setFilteredBenutzer] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState(null); 
  const [showModal, setShowModal] = useState(false);  
  const [showModalChar, setShowModalChar] = useState(false);
  const [showModalBannen, setShowModalBannen] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleBenutzer}`,  { 
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `${token}` : '',
    },
  })
      .then(response => response.json())
      .then(data => {
        setBenutzer(data);
        console.log(data);
        setFilteredBenutzer(data.slice(0, pageSize)); 
      })
      .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
  }, []); 

  useEffect(() => {
    // Filtern der Benutzerdaten basierend auf dem Suchbegriff und dem ausgewählten Status
    const filteredData = benutzer.filter(item => {
      // Überprüfen, ob "Alle Status" ausgewählt wurde
      if (statusFilter === null || statusFilter === "") {
        return item.benutzername.toLowerCase().includes(searchTerm.toLowerCase()) || item.charakters.some(charakter =>
          charakter.vorname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          charakter.nachname.toLowerCase().includes(searchTerm.toLowerCase())
        )
      } else {
        // Andernfalls nach dem Wert des aktuellBanned-Attributs und dem Benutzernamen filtern
        const isBanned = statusFilter === "1"; // true, wenn "1" ausgewählt ist
        return (
          item.aktuellBanned === (isBanned ? 1 : 0) &&
          (
            item.benutzername.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.charakters.some(charakter =>
              charakter.vorname.toLowerCase().includes(searchTerm.toLowerCase()) ||
              charakter.nachname.toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
        )
      }
    });
  
    // Aktualisierung der gefilterten Benutzerdaten und der Gesamtanzahl der Seiten
    setFilteredBenutzer(filteredData.slice(0, pageSize));
    setTotalPages(Math.ceil(filteredData.length / pageSize));
  }, [searchTerm, statusFilter, benutzer, pageSize]);

 
  const handlePageChange = page => {
    setCurrentPage(page);
  
    // Suchbegriff auf ungefilterten Fallakten anwenden

    const filteredData = benutzer.filter(item => {
      // Überprüfen, ob "Alle Status" ausgewählt wurde
      if (statusFilter === null || statusFilter === "") {
        return item.benutzername.toLowerCase().includes(searchTerm.toLowerCase()) || item.charakters.some(charakter =>
          charakter.vorname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          charakter.nachname.toLowerCase().includes(searchTerm.toLowerCase())
        )
      } else {
        // Andernfalls nach dem Wert des aktuellBanned-Attributs und dem Benutzernamen filtern
        const isBanned = statusFilter === "1"; // true, wenn "1" ausgewählt ist
        return (
          item.aktuellBanned === (isBanned ? 1 : 0) &&
          (
            item.benutzername.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.charakters.some(charakter =>
              charakter.vorname.toLowerCase().includes(searchTerm.toLowerCase()) ||
              charakter.nachname.toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
        )
      }
    });
    
    // Gesamtanzahl der Seiten basierend auf der gefilterten Datensatzlänge berechnen
    const totalFilteredPages = Math.ceil(filteredData.length / pageSize);
  
    // Datensätze für die aktuelle Seite extrahieren
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const newFilteredBenutzer = filteredData.slice(startIndex, endIndex);
  
    setFilteredBenutzer(newFilteredBenutzer);
  
    // Update der Pagination mit der Gesamtanzahl der Seiten
    setTotalPages(totalFilteredPages);
  };
  const handleStatusChange = value => {
  
    setStatusFilter(value);
 
    // Filtern der Benutzerdaten basierend auf dem ausgewählten Status und dem Suchbegriff
    const filteredData = benutzer.filter(item => {
      
      // Überprüfen, ob "Alle Status" ausgewählt wurde
      if (statusFilter === null || statusFilter === "") {
        return (
          item.benutzername.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.charakters.some(charakter =>
            charakter.vorname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            charakter.nachname.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      } else {
        // Andernfalls nach dem Wert des aktuellBanned-Attributs und dem Benutzernamen filtern
        const isBanned = statusFilter === "1"; // true, wenn "1" ausgewählt ist
        return (
          item.aktuellBanned === (isBanned ? 1 : 0) &&
          (
            item.benutzername.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.charakters.some(charakter =>
              charakter.vorname.toLowerCase().includes(searchTerm.toLowerCase()) ||
              charakter.nachname.toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
        )
      }
    }); 
  
  
    // Gesamtanzahl der Seiten basierend auf der gefilterten Datensatzlänge berechnen
    const totalFilteredPages = Math.ceil(filteredData.length / pageSize);
  
    // Datensätze für die aktuelle Seite extrahieren
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const newFilteredBenutzer = filteredData.slice(startIndex, endIndex);
  
    // Update der gefilterten Fallakten beim Statuswechsel
    setFilteredBenutzer(newFilteredBenutzer);
  
    // Update der Gesamtanzahl der Seiten beim Statuswechsel
    setTotalPages(totalFilteredPages);
  };
  const [bannedDays, setBannedDays] = useState(null);
  const handleRadioChange = (e) => {
    setBannedDays(e.target.value);
  };
  const handleBan = () => {
    // BANNN 
    const updatedData = {
      id: selectedUser.id,
      supporterId: setBenutzerId(),
      tage: bannedDays,
      fall: selectedSupportfall,
    }
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.bannen}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
      body: JSON.stringify(updatedData), 
    })
      .then(response => response.json())
      .then(data => {
        console.log('Datenbank erfolgreich aktualisiert:', data);
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Datenbank:', error);
      });
      window.location.reload();
  };
  const getUserRolle = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      return decodedToken.benutzerRollen; 
    }
    return null;
  };

  const setBenutzerId = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      return decodedToken.benutzerId; 
    }
    return null;
  };


  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex', color: 'white', marginTop: 50, width: '100%' }} >

        <Divider orientation="left" style={{ color: "white", borderColor: 'white', fontSize: 22, width: '100%'}} >
          Benutzer
          </Divider>

        <Space.Compact block layout='horizontal'>
          <Form layout='horizontal' style={{ background: `transparent`, borderColor: '#8b00b8', color: 'white', width: '100%' }}
            labelCol={{
              span: 8,
            }}
            wrappercol={{
              span: 16,
            }}
            labelAlign='right'
          >
          
            <Row>
              <Col flex="auto">
                <Input
                  placeholder="Suche nach Benutzernamen"
                  value={searchTerm}
                  allowClear
                  onChange={e => setSearchTerm(e.target.value)}
                  style={{ width: 500, marginBottom: 16 }}
                />
                  <Select
        style={{ width: 200, marginBottom: 16, marginRight: 16 }}
        placeholder="Filtern nach Status"
        
        onChange={(e) => handleStatusChange(e)}
        value={statusFilter}
      >
        <Option value="">Alle Status</Option>
        <Option value="1">gebannt</Option>
        <Option value="0">nicht gebannt</Option>
      </Select>
                </Col>
                <Col flex="none">
              <Form.Item>
                  <Button type="primary" htmlType="submit" className='button' onClick={handleStatusChange} style={{ float: 'right' }}>Suchen</Button>
                </Form.Item>
            
          
      </Col>
            </Row>
            <Row>
              <Col flex="auto">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalPages * pageSize}
                  onChange={handlePageChange}
                  style={{ marginTop: 16, textAlign: 'center', marginBottom: 16 }}
                />
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
              
              <Table dataSource={filteredBenutzer} columns={columns} pagination={false}  rowKey={(record) => record.id} />

              </Col>
            </Row>
            <Modal
              title={`Benutzerdetails: ${selectedUser.benutzername}`}
              open={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
          >  <div>
                    <p>ID: {selectedUser.id}</p>
                  </div>
          </Modal>

          <Modal
              title={`Bannen: ${selectedUser.benutzername}`}
              open={showModalBannen}
            onOk={handleBan}
            onCancel={() => setShowModalBannen(false)}
            width={650}
            height={300}
          >  <div>
          <p>Willst du {selectedUser.benutzername} wirklich bannen?</p>
          <Radio.Group onChange={handleRadioChange} value={bannedDays} optionType="button"
              buttonStyle="solid">
                  <Radio value={0}>Bis zu Klärung</Radio>
                  <Radio value={1}>1 Tag</Radio>
                  <Radio value={2}>2 Tage</Radio>
                  <Radio value={3}>3 Tage</Radio>
                  <Radio value={7}>7 Tage</Radio>
                  <Radio value={14}>14 Tage</Radio>
                  {( getUserRolle().includes('S-Admin') ) && ( 
                  <Radio value={100}>Permaban</Radio>)}
                  
                </Radio.Group>
                <br/>
                <Text>Supportfall: </Text>
                <br/>
                     <Select allowClear value={selectedSupportfall} onChange={handleSupportfallChange} style={{ width: 300 }} placeholder="Wähle einen Supportfall">
                {/* Optionen für Supportfälle aus der State-Variable supportfaelle rendern */}
                {supportfaelle.map(supportfall => (
                  <Select.Option key={supportfall.id} value={supportfall.anzeigeFallNummer}>
                    {supportfall.anzeigeFallNummer} - {supportfall.kategorie}
                  </Select.Option>
                ))}
              </Select>
                  </div>
          </Modal>

<Modal
    title={`${selectedChar.vorname} ${selectedChar.nachname}`}
    open={showModalChar}
  onOk={() => setShowModalChar(false) }
  onCancel={() => setShowModalChar(false)}
  
>  <div>
<br />

  <Row gutter={16}>
    <Col span={18} style={{ width: '75%' }}>
      <div style={{ display: 'flex' }}>
        <p style={labelStyle}>Name:</p>
        <p style={contentStyle}>{selectedChar.vorname} {selectedChar.nachname}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={labelStyle}>Geburtsdatum:</p>
        <p style={contentStyle}>{selectedChar.formatierterGebdat}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={labelStyle}>Herkunft:</p>
        <p style={contentStyle}>{selectedChar.herkunft}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={labelStyle}>Charstory:</p>
        <p style={contentStyle}>{selectedChar.charstory}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <p style={labelStyle}>Telefonnummer:</p>
        <p style={contentStyle}>{selectedChar.telNr}</p>
      </div> 
      <div style={{ display: 'flex' }}>
        <p style={labelStyle}>Position:</p>
        <p style={contentStyle}>{selectedChar.x} , {selectedChar.y} , {selectedChar.z} Dimension: {selectedChar.dimension} </p>
      </div> 
      <div style={{ display: 'flex' }}>
      <p style={labelStyle}>Whitelist:</p>
      <p style={contentStyle}>
      <Tooltip title={`${selectedChar.whitelist}` } style={contentStyle}>
            <p style={contentStyle}>
              {selectedChar.whitelist === 'offen' ? (
                <HourglassOutlined style={{ fontSize: '24px', color: 'orange', alignItems: 'center' }} />
              ) : selectedChar.whitelist === 'angenommen' ? (
                <CheckCircleOutlined style={{ fontSize: '24px', color: 'green', alignItems: 'center' }} />
              ) : (
                <QuestionCircleOutlined style={{ fontSize: '24px', color: 'blue', alignItems: 'center' }} />
              )}
            </p>
          </Tooltip> 
          </p>
          </div>
    </Col>
    <Col span={6} style={{ textAlign: 'right', width: '25%' }}>
    <img
  src={selectedChar.w === 1 ? "/Frau.webp" : "/mann.webp"}
  alt={selectedChar.w === 1 ? "Frau" : "Mann"}
  style={{ display: 'block', width: '100%' }}></img>
    </Col>
    <Row gutter={16}>
      <Col span={16}></Col>
      <Col span={8}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Tooltip title={`${selectedChar.energy}`}>
            <p style={labelStyleIcon}><img src="/thunder.png" style={labelStyleIcon} alt="Energy" /></p>
          </Tooltip>
          <Tooltip title={`${selectedChar.hunger}`}>
            <p style={labelStyleIcon}><img src="/food.png" style={labelStyleIcon} alt="Hunger" /></p>
          </Tooltip>
          <Tooltip title={`${selectedChar.durst}`}>
            <p style={labelStyleIcon}><img src="/drink.png" style={labelStyleIcon} alt="Durst" /></p>
          </Tooltip>
          {selectedChar.char_bankaccounts && selectedChar.char_bankaccounts.length > 0 && selectedChar.char_bankaccounts[0].bankaccount && (
          <Tooltip title={`${selectedChar.char_bankaccounts[0]?.bankaccount?.geld} $`}>
            <p style={labelStyleIcon}><img src="/bank.png" style={labelStyleIcon} alt="Durst" /></p>
          </Tooltip>)}
          <Tooltip title={`${selectedChar.bargeld} $`}>
            <p style={labelStyleIcon}><img src="/chash.png" style={labelStyleIcon} alt="Durst" /></p>
          </Tooltip>
        </div>
      </Col>
    </Row>
  </Row>
        </div>
</Modal>
          </Form>

      
        </Space.Compact>
      </Space>
    </div>
  );
};

export default Benutzer;

const serverConfig = {
    baseUrl: 'https://be.nelion-rp.eu', 
    endpoints: {
      anmelden: '/anmelden',
      alleBenutzer: '/alleBenutzer',
      alleBenutzerNamen: '/alleBenutzerNamen',
      alleSupporter: '/alleSupporter',
      alleSupporterfaelle: '/alleSupporterfaelle',
      alleSupporterfaelleZuBenutzer: '/alleSupporterfaelleZuBenutzer',
      alleRollen: '/alleRollen',
      bannen: '/bannen',      
      bearbeiteBenutzer: '/bearbeiteBenutzer',
      bearbeiteSupportfall: '/bearbeiteSupportfall',
      benutzer: '/benutzer',
      benutzerRollen: '/benutzerRollen',
      erstelleSupportfall: '/erstelleSupportfall',
      entbannen: '/entbannen',
      itemAnlegenEssen: '/itemAnlegenEssen',
      supportfall: '/supportfall',
      supportfaelleBenutzer: '/supportfaelleBenutzer'
    },
  };
  
  export default serverConfig;
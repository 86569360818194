import React from 'react';
import { Table , Row, Col, Tooltip , Button } from 'antd';
import { HourglassOutlined , CheckCircleOutlined , QuestionCircleOutlined } from '@ant-design/icons';

const CharakterListe = ({ charaktere }) => {
  const labelStyleIcon = {
    marginRight: '8px',
    width: '24px',
    height: '24px',
  };
  const columns = [
    , {
      title: 'Whiteliststatus',
      dataIndex: 'whiteliststatus',
      key: 'whiteliststatus',
      render: (text, record) => {
      let iconToShow;

      if (record.whitelist === 'offen') {
        iconToShow = <HourglassOutlined style={{ fontSize: '24px' , color: 'orange' ,alignItems: 'center'  }} />;
      } else if (record.whitelist === 'angenommen') {
        iconToShow = <CheckCircleOutlined style={{ fontSize: '24px' , color: 'green' ,alignItems: 'center' }} />;
      } else {
        // Optional: Setzen Sie ein Standard-Symbol für andere Zustände
        iconToShow = <QuestionCircleOutlined  sstyle={{ fontSize: '24px'  }} />;
      }
  
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Tooltip title={`${record.whitelist}`}> <p style={labelStyleIcon}>
            {iconToShow} </p>
          </Tooltip>
        </div>
      );
    },
    
  },
    {
      title: 'Vorname',
      dataIndex: 'vorname',
      key: 'vorname',
    },
    {
      title: 'Nachname',
      dataIndex: 'nachname',
      key: 'nachname',
    },
    {
      title: 'Geburtsdatum',
      dataIndex: 'geburtsdatum',
      key: 'geburtsdatum',
      render: (text, record) => new Date(text).toLocaleDateString('de-DE', {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      }),
    },
    {
      title: 'Herkunft',
      dataIndex: 'herkunft',
      key: 'herkunft',
    },
    {
      title: 'Charstory',
      dataIndex: 'charstory',
      key: 'charstory',
      render: (text) => (
        <Tooltip title={text} overlayStyle={{ maxWidth: '500px' }}>
          <span>{text.slice(0, 50)}{text.length > 50 ? '...' : ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Telefonnummer',
      dataIndex: 'telNr',
      key: 'telNr',
    },
    {
      title: 'Zustand',
      dataIndex: 'zustand',
      key: 'zustand',
      render: (text, record) =>
      <> 
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Tooltip title={`${record.energy}`}>
            <p style={labelStyleIcon}><img src="/thunder.png" style={labelStyleIcon} alt="Energy" /></p>
          </Tooltip>
          <Tooltip title={`${record.hunger}`}>
            <p style={labelStyleIcon}><img src="/food.png" style={labelStyleIcon} alt="Hunger" /></p>
          </Tooltip>
          <Tooltip title={`${record.durst}`}>
            <p style={labelStyleIcon}><img src="/drink.png" style={labelStyleIcon} alt="Durst" /></p>
          </Tooltip>
          {record.char_bankaccounts && record.char_bankaccounts.length > 0 && record.char_bankaccounts[0].bankaccount && (
          <Tooltip title={`${record.char_bankaccounts[0]?.bankaccount?.geld} $`}>
            <p style={labelStyleIcon}><img src="/bank.png" style={labelStyleIcon} alt="Geld" /></p>
          </Tooltip>)}
          <Tooltip title={`${record.bargeld} $`}>
            <p style={labelStyleIcon}><img src="/chash.png" style={labelStyleIcon} alt="bank" /></p>
          </Tooltip>
        </div>
      
    </>
    }, {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    render: (text, record) => 
      <div style={{ display: 'flex' }}>
      <p >X: {record.x} , Y: {record.y} , Z: {record.z} 
      <br/> Dimension: {record.dimension} </p>
    </div> 
    
  
  
},
  
    {
      title: 'Aktionen',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button type="default" onClick={() => handleButtonClick(record)}>
          Infos
        </Button>
        {' '}
      <Button type="primary" onClick={() => handleButtonClick(record)}>
      Bannen
    </Button>
    </>
      ),
      align: 'left',
    },
  ];

  const handleButtonClick = (record) => {
    // Implementieren Sie hier die Logik für die Button-Aktion
    //setSelectedUser(record);
    //setShowModalBannen(true);
  }

  return <Table dataSource={charaktere} columns={columns} rowKey={(record) => record.id} />
};

export default CharakterListe;


import Login from './Login';
import Benutzer from './Benutzer';
import Startseite from './Startseite';
import ServerControlPanel from './ServerControlPanel';
import Support from './Support';
import { jwtDecode } from 'jwt-decode';

export function authMiddleware (komponenteName)  {
  
    const token = localStorage.getItem('token');
    let userHasRequiredRoles = false;
    // Fügen Sie hier Ihre Logik zur Überprüfung der Rollen hinzu
   console.log("Component", komponenteName);
    if (token) {
      // Redirect to login if there's no token or the user doesn't have required roles
      const userRoles = getUserRolesFromToken(token);
      //console.log('von Anwendung', userRoles);
      let allowedRolesNeu;
      switch (komponenteName) {
        case 'Startseite':
           allowedRolesNeu = ['S-Admin', 'Support','Game-Design', 'Lead Dev'];
           break;
        case 'Support':
          allowedRolesNeu = ['Support', 'Benutzer','S-Admin'];
          break;
        case 'Benutzer':
           allowedRolesNeu = ['S-Admin', 'Support'];
           break;
        default:
           allowedRolesNeu = [''];
           break;
      }
      console.log(allowedRolesNeu);
      
       userHasRequiredRoles = checkRequiredRoles(userRoles, allowedRolesNeu);
       console.log(userHasRequiredRoles);
       if(userHasRequiredRoles)
       {
        let isTokenExpiredToken = isTokenExpired(token);
        if (!isTokenExpiredToken) {
        let comp; 
        switch (komponenteName) {
          case 'Startseite':
            comp = <Startseite style={{ padding: '0 24px', width: '100%' }}/>;
            break;
          case 'ServerControlPanel':
            comp = <ServerControlPanel style={{ padding: '0 24px', width: '100%' }}/>;
            break;
          case 'Support':
            comp = <Support style={{ padding: '0 24px', width: '100%' }}/>;
            break;
          case 'Benutzer':
            comp = <Benutzer style={{ padding: '0 24px', width: '100%' }} />;
            break;
          default:
            comp = <Login />;
            break;
        }
        return comp;
      } else {
        // Token abgelaufen, weiterleiten zur Startseite (nur einmal)
        if (!window.location.href.includes('/')) {
          window.location.href = '/';
        }
        return null;
      }
    }
  }

  // Wenn kein Token vorhanden oder der Benutzer nicht die erforderlichen Rollen hat
  if (!window.location.href.includes('/')) {
    window.location.href = '/'; // Weiterleiten zur Startseite (nur einmal)
  }
  return null;
}

  const isTokenExpired = (token) => {
    try {
        const decodedToken = decodeToken(token);
        const expirationDate = decodedToken.exp * 1000; // Umwandeln in Millisekunden
        const currentTimestamp = new Date().getTime();
  
        return expirationDate < currentTimestamp;
    } catch (error) {
        console.error('Fehler beim Überprüfen des Ablaufdatums des Tokens:', error.message);
        return true; // Im Fehlerfall gehen wir davon aus, dass das Token abgelaufen ist
    }
  };
const getUserRolesFromToken = (token) => {
  try {
    const decodedToken = decodeToken(token);
    return decodedToken.benutzerRollen || [];
  } catch (error) {
    console.error('Fehler beim Dekodieren des Tokens:', error.message);
    return [];
  }
};

const checkRequiredRoles = (userRoles, allowedRoles) => {
  // Überprüfen Sie, ob der Benutzer die erforderlichen Rollen hat
  console.log('allowedRoles:', allowedRoles);  
  console.log('allow:', userRoles);  // Hinzufügen dieser Zeile

  console.log(allowedRoles.some((role) => userRoles.includes(role)));
  return allowedRoles.some((role) => userRoles.includes(role));
};

const decodeToken = (token) => {
  // Implementieren Sie Ihre Logik zum Decodieren des Tokens (verwenden Sie Ihre JWT-Bibliothek)
  // Beispiel: Hier wird angenommen, dass das Token im JSON-Format ist
  return JSON.parse(atob(token.split('.')[1]));
};

export const hatUserRolle = (value) => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.benutzerRollen.includes(value)) {
      return true;
    }
    return false;
  }
  return false;
};

export const setBenutzerId = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.benutzerId; 
  }
  return null;
};

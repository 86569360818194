import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Radio , Tooltip, Table, DatePicker, Tag, message,Button, Space, Typography , Divider, List, Avatar, Modal,ConfigProvider  } from 'antd';
import { LeftOutlined, UserOutlined , CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import serverConfig from '../config';
import moment from 'moment';
import 'moment/locale/de';
import locale from 'antd/locale/de_DE';
import dayjs from 'dayjs';
import SupportGespraechModal from './SupportGespraech';
import 'dayjs/locale/de-at';
import {  setBenutzerId , hatUserRolle } from './authMiddleware'; 

const { Text } = Typography;

const SupportfallBearbeiten  = ({ mode }) => {
   
    const { id } = useParams();
    const [supportfallData, setSupportfallData] = useState({});
    const [form] = Form.useForm();
    const [bearbeiter, setBearbeiter] = useState([]);
    const [betroffeneUser, setBetroffeneUser] = useState([]);
    const [melder, setMelder] = useState([]);
    const [grund, setGrund] = useState();
    const [status, setStatus] = useState();
    const [kategorie, setKategorie] = useState();
    const [supporter, setSupporter] = useState([]);
    const [benutzer, setBenutzer] = useState([]);
    const [datum, setDatum] = useState();
    const [supportGespraeche, setSupportGespraeche] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [statusDB,setStatusDB] = useState([]);
    const [melderDB,setMelderDB] = useState([]);
    const [bearbeiteteSupportGespraeche, setBearbeiteteSupportGespraeche] = useState([]);
    const [bearbeitenIndex, setBearbeitenIndex] = useState(null);
    const [dataSource, setDataSource] = useState(null);
   
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [render, setRender] = useState(false);
  const [bannPopupSichtbar, setBannPopupSichtbar] = useState(false);
  const [modalSelectedUsers, setModalSelectedUsers] = useState([]);
  
  const [selectedUser, setSelectedUser] = useState([]);
  const [bannedDays, setBannedDays] = useState(null);
    const [modalSelectedSupporter, setModalSelectedSupporter] = useState([]);
    const [modalSelectedInhalt, setModalSelectedInhalt] = useState([]);
    const [modalSelectedErstelltAm, setModalSelectedErstelltAm] = useState([]);
    const [bearbeitungsWerte, setBearbeitungsWerte] = useState({
      erstelltAm: moment(),
      inhalt: '',
      selectedUsers: '',
      selectedSupporter: '',
    });
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const kategorien = [
      { value: 'Diskriminierung & Übergriff', label: 'Diskriminierung & Übergriff' },
      { value: 'Fail-RP', label: 'Fail-RP' },
      { value: 'Power-RP', label: 'Power-RP' },
      { value: 'RDM', label: 'RDM' },
      { value: 'VDM', label: 'VDM' },
      { value: 'Meta-Gaming', label: 'Meta-Gaming' },
      { value: '3rd Person Spotting', label: '3rd Person Spotting' },
      { value: 'Backseat-Gaming', label: 'Backseat-Gaming' },
      { value: 'Scripting', label: 'Scripting' },
      { value: 'Bug Using', label: 'Bug Using' },
      { value: 'Doppel Account', label: 'Doppel Account' },
      { value: 'Identitätsbetrug', label: 'Identitätsbetrug' },
      { value: 'Abwesenheit', label: 'Abwesenheit' },
      { value: 'Urlaub', label: 'Urlaub' },
      { value: 'Support Beobachtung', label: 'Support Beobachtung' }
  ];
  const statuse = [
    { value: 'offen', label: 'offen' },
    { value: 'in Bearbeitung', label: 'in Bearbeitung' },
    { value: 'geschlossen', label: 'geschlossen' },
  ];
  useEffect(() => {
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleBenutzerNamen}`,  { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
    })
        .then(response => response.json())
        .then(data => {
            const benutzerBetroffen = data.map(benutzerdb => ({
                label: benutzerdb.benutzername,
                value: benutzerdb.id,
              }));

          setBenutzer(benutzerBetroffen);
        })
        .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
    }, []); 
    useEffect(() => {
      fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleSupporter}`,  { 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `${token}` : '',
        },
      })
          .then(response => response.json())
          .then(data => {
           
            const benutzerBetroffen = data.map(benutzerdb => ({
                label: benutzerdb.benutzername,
                value: benutzerdb.id,
              }));

            setSupporter(benutzerBetroffen);
          })
          .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
      }, []); 




      
  useEffect(() => {
    // Fetch support case details based on supportFallId
 
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.supportfall}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
    })
      .then(response => response.json())
      .then(data => {
        setSupportfallData(data);
        
        const datumDate = dayjs(data.erstellt_am, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        setDatum(datumDate);
       

        const status = {
            label: data.status,
            id: data.status,
          };
          const benutzerBetroffen = data.betroffenebenutzersupportfalls.map(benutzerdb => ({
            label: benutzerdb.benutzer.benutzername,
            value: benutzerdb.benutzer.id,
          }));
          const melder = data.meldersupportfalls.map(benutzerdb => ({
            label: benutzerdb.benutzer.benutzername,
            value: benutzerdb.benutzer.id,
          }));
          const bearbeiter = data.bearbeitersupportfalls.map(benutzerdb => ({
            label: benutzerdb.benutzer.benutzername,
            value: benutzerdb.benutzer.id,
          }));
       
         setStatusDB(status);
         setStatus(status);
         setKategorie(data.kategorie);
        setGrund(data.grund);
        setBetroffeneUser(benutzerBetroffen);
        setMelder(melder);
        console.log('benutzer und so',data.betroffenebenutzersupportfalls );
        setDataSource(data.betroffenebenutzersupportfalls.map((benutzer) => ({
          ...benutzer.benutzer,
          aktuellBanned: benutzer.benutzer.aktuellBanned,
          key: benutzer.benutzer.id,
          selected: betroffeneUser.some((selectedUser) => selectedUser.value === benutzer.benutzer.id),
        })));
        
        setBearbeiter(bearbeiter);
        const supportGespraeche = data.supportgespraeches.map(s => ({
            id: s.id,
            erstelltAm: s.erstellt_am,
            inhalt: s.inhalt,
          benutzer: s.benutzersupportgespraeches.map(user => ({
            id: user.benutzer.id,
            label: user.benutzer.benutzername
          })),
          supporter: s.supportersupportgespraeches.map(user => ({
            id: user.benutzer.id,
            label: user.benutzer.benutzername
          }))
        }));
        const updatedSupportGespraeche = supportGespraeche.map(item => ({
          ...item,
          erstelltAm: dayjs(data.erstellt_am, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD.MM.YYYY HH:mm')
      }));
      
          setSupportGespraeche(updatedSupportGespraeche);           
          
        setRender(true);
      })
      .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
  }, []);
  const handleRadioChange = (e) => {
    setBannedDays(e.target.value);
  };
  const handleBan = () => {
    console.log('user', selectedUser);
    // BANNN 
    const updatedData = {
      id: selectedUser.id,
      supporterId: setBenutzerId(),
      tage: bannedDays,
      fall: id,
    }
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.bannen}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
      body: JSON.stringify(updatedData), 
    })
      .then(response => response.json())
      .then(data => {
        console.log('Datenbank erfolgreich aktualisiert:', data);
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Datenbank:', error);
      });
      window.location.reload();
  };
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        console.log('data' ,dataSource);
        let iconToShow;
        let contentToShow;
        if (record.aktuellBanned === 1) {
          iconToShow = <CloseCircleOutlined style={{ fontSize: '24px' , color: 'red' , alignItems: 'center'  }} />;
          if (record.bannedBis !== null) {
           
            const gebanntBis = new Date(record.bannedBis);
            if (gebanntBis.getFullYear() === 2030 && gebanntBis.getMonth() === 11 && gebanntBis.getDate() === 31) {
              contentToShow = 'Bis zur Klärung';
            } else {
              contentToShow = gebanntBis.toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              });
            }
          } else {
            contentToShow = 'Permabann';
          }
        } else {      
          iconToShow = <CheckCircleOutlined style={{ fontSize: '24px' , color: 'green' , alignItems: 'center' }} />;
        } 
    
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {contentToShow ? (
            <Tooltip title={`${contentToShow}`}> 
              {iconToShow}
            </Tooltip>
            ) : (
              iconToShow
            )}
          </div>
        );
      },
    },
    {
      title: 'Benutzername',
      dataIndex: 'benutzername',
      key: 'benutzername',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
         {text}
        </div>
      ),
    },
   
    {
      title: 'Aktionen',
      dataIndex: '',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => handleBannButtonClick(record)} >
          Bannen
        </Button>
      ),
    },
  ];

  const handleSubmit = async (values) => {
    
      const supportFall = {
        id,
        kategorie,
        status,
        grund,
        betroffeneUser,
        melder,
        bearbeiter,
        supportGespraeche,
      };

      try {
        const response = await fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.bearbeiteSupportfall}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token ? `${token}` : '',
            },
            body: JSON.stringify(supportFall),
          });
        if (response.ok) {
          const data = await response.json();
          message.success('Supportakte erfolgreich erstellt!');
          form.resetFields();
          navigate('/support');
        } else {
          console.error('Fehler beim Aktualisieren der Datenbank:', response.status);
          message.error('Fehler beim Erstellen der Supportakte');
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Datenbank:', error);
        message.error('Fehler beim Erstellen der Supportakte');
      }
  
  };

  const onBackLinkClick = () => {
    navigate(-1);
  };
 
const handleGespraechHinzufuegen = () => {
  setBearbeitenIndex(null);
  setBearbeitungsWerte(null);
  setIsModalVisible(true);
 
};
const handleBannButtonClick = (user) => {
  
  
  
  setSelectedUser(user);
  setBannPopupSichtbar(true);
};




// Funktion, um Popup zu schließen
const handleBannAbbrechen = () => {
  setBannPopupSichtbar(false);
};

  const handleEditGespraech = (gespraech) => {
    // Setze das ausgewählte Supportgespräch als Initialwerte für das Modal
    setBearbeitenIndex(gespraech.id);
    setBearbeitungsWerte({
        erstelltAm: gespraech.erstelltAm,
        inhalt: gespraech.inhalt,
        selectedUsers: gespraech.benutzer.map(user => ({
          id: user.id,
          label: user.label
      })),
        selectedSupporter: gespraech.supporter.map(user =>  ({
          id: user.id,
          label: user.label
      }))
    });
    setIsModalVisible(true); 
};

const handleModalOk = (gespraech) => {
   if (bearbeitenIndex !== null) {
   
    const gespraeche = supportGespraeche.find(g => g.id === bearbeitenIndex);
    let bearbeitet = false;
    console.log('aus db',gespraeche);
    console.log('bearbeitet',gespraech);
    console.log('vorbearbeitung',bearbeitungsWerte);
    if(gespraech.inhalt != bearbeitungsWerte.inhalt)
    {
      gespraeche.inhalt = gespraech.inhalt;
      bearbeitet = true;
    }
    if(!dayjs(gespraech.erstelltAm).isSame(dayjs(bearbeitungsWerte.erstelltAm)))
    {
      gespraeche.erstelltAm = dayjs(gespraech.erstelltAm).format('DD.MM.YYYY HH:mm');
      bearbeitet = true;
    }
    const selectedUsers = bearbeitungsWerte.selectedUsers.map(user => user.id);
    const geloeschteUser = selectedUsers.filter((id) => !gespraech.selectedUsers.includes(id));
    const hinzufuegteUser = gespraech.selectedUsers.filter((id) => !selectedUsers.includes(id));

    if(geloeschteUser.length > 0 || hinzufuegteUser.length > 0)
    {
      const filteredArray = benutzer.filter(obj => gespraech.selectedUsers.includes(obj.id));
      gespraeche.benutzer  = filteredArray;      
      bearbeitet = true;
    }

    const selectedSupport = bearbeitungsWerte.selectedSupporter.map(user => user.id);
    const geloeschteSupport = selectedSupport.filter((id) => !gespraech.selectedSupporter.includes(id));
    const hinzufuegteSupport = gespraech.selectedSupporter.filter((id) => !selectedSupport.includes(id));

    if(geloeschteSupport.length > 0 || hinzufuegteSupport.length > 0)
    {
      const filteredArray = benutzer.filter(obj => gespraech.selectedSupporter.includes(obj.id));
      gespraeche.supporter  = filteredArray;      
      bearbeitet = true;
    }

    if(bearbeitet)
    {
      setBearbeiteteSupportGespraeche(prevBearbeiteteGespraeche => [...prevBearbeiteteGespraeche, gespraech]);
    }
   }
   else {
    console.log(gespraech.erstelltAm);
    gespraech.erstelltAm = dayjs(gespraech.erstelltAm).format('DD.MM.YYYY HH:mm');
    const filteredArray1 = benutzer.filter(obj => gespraech.selectedUsers.includes(obj.id));
    gespraech.benutzer  = filteredArray1;      
    const filteredArray = benutzer.filter(obj => gespraech.selectedSupporter.includes(obj.id));
    gespraech.supporter  = filteredArray;      
    setSupportGespraeche(prevBearbeiteteGespraeche => [...prevBearbeiteteGespraeche, gespraech]);
   }
  setIsModalVisible(false);
  setBearbeitenIndex(null);
  setBearbeitungsWerte({
    erstelltAm: moment(),
    inhalt: '',
    selectedUsers: '',
    selectedSupporter: '',
  });
};

useEffect(() => {
  if (supportfallData && supportfallData.betroffenebenutzersupportfalls) {
    setDataSource(supportfallData.betroffenebenutzersupportfalls.map((benutzer) => ({
      ...benutzer.benutzer,
      aktuellBanned: benutzer.benutzer.aktuellBanned,
      key: benutzer.benutzer.id,
      selected: betroffeneUser.some((selectedUser) => selectedUser.value === benutzer.benutzer.id),
    })));
  }
}, [supportfallData, betroffeneUser]);


  return (
    
    <Space direction="vertical" size="middle" style={{ display: 'flex', color: 'white', marginTop: 50, width: '100%' }}>
  
      <Divider orientation="left" style={{ color: 'white', borderColor: 'white', fontSize: 22, width: '100%' }}>
      {mode === 'edit' ? 'Supportfall bearbeiten' : 'Supportfall anzeigen'} {supportfallData.anzeigeFallNummer}
      </Divider>
      <Link to="#" onClick={onBackLinkClick} style={{ fontSize: '14px', color: '#fff', textDecorationLine: 'none' }}>
        <LeftOutlined style={{ fontSize: '14px', color: '#fff', verticalAlign: 'middle' }} /> zurück
      </Link>
              <Form
        form={form}
        style={{ background: `transparent`, width: '50%' }}
        labelCol={{
          span: 8,
        }}
        wrappercol={{
          span: 16,
        }}
        labelAlign="right"
       
      >
        <Form.Item label="Fallnummer">
        <Text>{supportfallData.anzeigeFallNummer}</Text>
        </Form.Item>
        
        <Form.Item label="Erstellt am">
        <DatePicker  showTime={false} disabled value={dayjs(datum)} format={'DD.MM.YYYY'}/>
   
        </Form.Item>
        <Form.Item label="Kategorie"  rules={[{ required: true, message: 'Bitte wähle eine Kategorie aus!' }]}>
          <Select  allowClear value={kategorie} onChange={(selectedKategorie) => setKategorie(selectedKategorie)}
              options={kategorien}
              disabled={mode === 'view'}
          />     
         </Form.Item>
         {render && (
            <>
         <Form.Item label="Status"  rules={[{ required: true, message: 'Bitte wähle einen Status aus!' }]}>
  <Select allowClear value={status} disabled={mode === 'view'} onChange={(selectedStatus) => setStatus(selectedStatus)} options={statuse} />
</Form.Item>
           
         <Form.Item label="Grund" name="grund" rules={[{ required: true, message: 'Bitte den Grund eingeben!' }]}>
  <Input.TextArea placeholder="Grund eingeben" disabled={mode === 'view'} rows={4} defaultValue={grund} onChange={(event) => setGrund(event.target.value)} />
</Form.Item> </>)}

        <Form.Item label="Betroffene Benutzer*innen">
          <Select
            mode="multiple"
            placeholder="Betroffene Benutzer*innen auswählen"
            value={betroffeneUser}
            disabled={mode === 'view'}
            onChange={(selectedUsers) => {
              setBetroffeneUser(selectedUsers);
              setDataSource(supportfallData.betroffenebenutzersupportfalls.map((benutzer) => ({
                ...benutzer.benutzer,
                aktuellBanned: benutzer.benutzer.aktuellBanned,
                key: benutzer.benutzer.id,
                selected: betroffeneUser.some((selectedUser) => selectedUser.value === benutzer.benutzer.id),
              })));
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={benutzer}
          >          </Select>
        </Form.Item>
        <Form.Item label="Support*in">
          <Select
            mode="multiple"
            value={bearbeiter}
            disabled={mode === 'view'}
            placeholder="Bearbeiter auswählen"
            onChange={(selectedBearbeiter) => setBearbeiter(selectedBearbeiter)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={supporter}
          >
          </Select>
        </Form.Item>
        <Form.Item label="Melder*in">
          <Select
            mode="multiple"
            value={melder}
            disabled={mode === 'view'}
            placeholder="Melder auswählen"
            onChange={(selectedMelder) => setMelder(selectedMelder)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={benutzer}
          >
            
          </Select>
        </Form.Item>

       
      </Form>

      <Divider orientation="left" style={{ color: 'white', borderColor: 'white', fontSize: 22, width: '100%' }}>
        Supportgespräche
      </Divider>
      <List
          itemLayout="horizontal"
          dataSource={supportGespraeche}
          renderItem={(item, index) => (
            <List.Item actions={ mode !== 'view' &&[
              
                <Button type="primary" onClick={() => handleEditGespraech(item)}>Bearbeiten</Button>
            ]}>
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={item.erstelltAm}
                description={
                  <div>
                    <div>{item.inhalt}</div>
                    <div>Anwesende Benutzer*innen: {item.benutzer.map((user) => user.label).join(', ')}</div>
                    <div>Supporter*innen: {item.supporter.map((user) => user.label).join(', ')}</div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' , marginBottom: '16px' }}>
        {mode !== 'view' && (
          <Button type="default" onClick={handleGespraechHinzufuegen}>
              Supportgespräch hinzufügen
          </Button> 
        )}
        </div>
        <Modal
          title={bearbeitenIndex !== null ? 'Supportgespräch bearbeiten' : 'Supportgespräch hinzufügen'}
      visible={isModalVisible}
      onOk={handleModalOk}
      footer={null}
      onCancel={() => {
        setIsModalVisible(false);
        setBearbeitenIndex(null);
        setBearbeitungsWerte({
          erstelltAm: moment(),
          inhalt: '',
          selectedUsers: '',
          selectedSupporter: '',
        });
      }}
    >
    
   
      <SupportGespraechModal
        onSave={handleModalOk}
        benutzer={benutzer}
        supporter={supporter}
        bearbeitungsWerte={bearbeitenIndex !== null ? bearbeitungsWerte : null}
      />
      </Modal>
      <Divider orientation="left" style={{ color: "white", borderColor: 'white', fontSize: 22, width: '100%'}} >
      Auswirkungen
          </Divider>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
<Modal
              title={`Bannen: ${selectedUser.benutzername}`}
              open={bannPopupSichtbar}
              onOk={handleBan}
              onCancel={handleBannAbbrechen}
            width={650}
            height={300}
          >  <div>
          <p>Willst du {selectedUser.label} wirklich bannen?</p>
          <Radio.Group onChange={handleRadioChange} value={bannedDays} optionType="button"
              buttonStyle="solid">
                  <Radio value={0}>Bis zu Klärung</Radio>
                  <Radio value={1}>1 Tag</Radio>
                  <Radio value={2}>2 Tage</Radio>
                  <Radio value={3}>3 Tage</Radio>
                  <Radio value={7}>7 Tage</Radio>
                  <Radio value={14}>14 Tage</Radio>
                  {( hatUserRolle('S-Admin') ) && ( 
                  <Radio value={100}>Permaban</Radio>)}
                  
                </Radio.Group>
                                 </div>
          </Modal>
      {/* Popup zum Bannen von Benutzern */}
  


      {mode !== 'view' && (
        <Button style={{ float: 'right' }} type="primary" onClick={() => handleSubmit()}>
          Supportfall aktualisieren
        </Button>
      )}

 
    </Space>
  );
};




export default SupportfallBearbeiten;
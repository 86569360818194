import React, {useEffect , useState } from 'react'
import serverConfig from '../config';
import { Form, Input, Divider, message ,Tag, Tooltip,Space, Row, Col, Pagination, Button, Table, Select, Modal, Checkbox } from 'antd';
import { useNavigate ,useParams , Link  } from 'react-router-dom';
import { LeftOutlined,InfoCircleOutlined } from '@ant-design/icons';
import CharakterListe from './CharakterListe';
import { hatUserRolle, setBenutzerId } from './authMiddleware';

import moment from 'moment';

const BenutzerInfo = () => {
  

  const { userId } = useParams();
  const navigate = useNavigate();
  
  const [alleBenutzer, setAlleBenutzer] = useState([]);
  const [benutzer, setBenutzer] = useState([]);
  const [benutzername, setBenutzername] = useState([]);
  const [admin, setAdmin] = useState(true);
  
  const [charakter, setCharakter] = useState([]);

  const [abteilung, setAbteilung] = useState([]);
  const [rang, setRang] = useState([]);
  const [render, setRender] = useState([]);
  const [banned, setBanned] = useState(false); 
  const [perma, setPerma] = useState(false); 
  const [geladen, setGeladen] = useState(false); 
  const [klaerung, setKlaerung] = useState(false); 
  
  const [gebanntBis, setGebanntBis] = useState(false); 
  
  const token = localStorage.getItem('token');


  const onBackLinkClick = () => {
    navigate(-1);
  };
  const [rollen, setRollen] = useState([]);
  useEffect(()=> {
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleRollen}`,  { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      } 
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Fehler beim Abrufen der Daten. HTTP-Status: ${response.status}`);
      }
      return response.json();
    })
      .then(data => {
        const updatedRollen = data.map(element => ({

          label: element.beschreibung,
          value: element.id,
        }));
        console.log('rollen', updatedRollen);
        setRollen(updatedRollen);

      })
      .catch(err => console.log(err));
  }, [])

  useEffect(() => {
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.benutzer}/${userId}`,  { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      }
    })
    .then(response => {
      if (!response.ok) {
        navigate('/');
        throw new Error(`Fehler beim Abrufen der Daten. HTTP-Status: ${response.status}`);
     
      }
      return response.json();
    })
      .then(data => {
        setBenutzer(data);
        setBenutzername(data.benutzername);
        setAbteilung(data.abteilung);
        setCharakter(data.charakters);
        if(data.aktuellBanned === 1 && data.bannedBis !== null )
        {
          const bannedBis = new Date(data.bannedBis).toLocaleDateString('de-DE', {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
               minute: "2-digit"
          })
          const bannedBisDate = new Date(data.bannedBis);
          if (bannedBisDate.getFullYear() === 2030 && bannedBisDate.getMonth() === 11 && bannedBisDate.getDate() === 31) {
            // Setzen Sie den Zustand für die Klärung
            setKlaerung(true);
          }else{
              setBanned(true);
          }
          setGebanntBis(bannedBis);
        }else if(data.aktuellBanned === 1 && data.bannedBis === null){
          setPerma(true);
        }
      })
      .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
  }, []);

  useEffect(() => {
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.benutzerRollen}/${userId}`,  { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      }
    }).then(response => response.json())
      .then(data => {
         const rollenArray = data.benutzer_rollens.map(rolle => ({
           label: rolle.rollen.beschreibung,
           value: rolle.rollen.id,
         }));
         setRang(rollenArray);
       })
      .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
  }, []);
  useEffect(() => {
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleBenutzerNamen}`,  { 
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `${token}` : '',
      },
    })
        .then(response => response.json())
        .then(data => {
          setAlleBenutzer(data);
        })
        .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
    }, []); 
    const onChangeBenutzername = (input) => {
    const inputValue = input.target.value;
   
      setBenutzername(inputValue);
  };

  const isBenutzernameAvailable = (benutzername, alleBenutzer) => {
    // Überprüfen, ob der Benutzername im Array vorhanden ist
    return !alleBenutzer.some(user => user.benutzername === benutzername);
  };
  
  const onChangeRollen = (input) => {
    setRang(input);
  };

  const handleBearbeiten = async  () => {
    if(benutzer.benutzername !== benutzername) {
       if (!isBenutzernameAvailable(benutzername, alleBenutzer)) {
        
        Modal.error({
          title: 'Fehler',
          content: 'Der Benutzername ist bereits vergeben.',
        });
        return;
      }
    }
  
  
    const benutzerBeabeitet = {
      id: userId,
      benutzername: benutzername,
      team: abteilung,
      rollen: rang,
      bearbeiter: setBenutzerId()
     
    }
    try {
      const response = await fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.bearbeiteBenutzer}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `${token}` : '',
        },
        body: JSON.stringify(benutzerBeabeitet),
      });

      if (response.ok) {
        const data =  response.json();
        message.success('Benutzer erfolgreich bearbeitet!');
        
        navigate(-1);
      } else {
        console.error('Fehler beim Aktualisieren der Datenbank:', response.status);
        message.error('Fehler beim Bearbeiten des Benutzers');
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Datenbank:', error);
      message.error('Fehler beim Erstellen der Supportakte');
    }


  };

  //--- Supportfaelle fuer Supporter
  const [filteredSupportfaelle, setFilteredSupportfaelle] = useState([]);
  const [supportfaelle, setSupportfaelle] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = page => {
    setCurrentPage(page);
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const totalFilteredPages = Math.ceil(supportfaelle.length / pageSize);
    const newFilteredSupportfaelle = supportfaelle.slice(startIndex, endIndex);
    setFilteredSupportfaelle(newFilteredSupportfaelle);
    setTotalPages(totalFilteredPages);
  };
  const columns = [
   
    {
      title: 'Erstellt am',
      dataIndex: 'erstellt_am',
      key: 'erstellt_am',
      render: (erstellt_am) => moment(erstellt_am).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'offen' ? 'red' : status === 'in Bearbeitung' ? 'orange' : 'green'}>{status}</Tag>
      ),
    }, {
      title: 'Fallnummer',
      dataIndex: 'anzeigeFallNummer',
      key: 'anzeigeFallNummer',
    },
    {
      title: 'Kategorie',
      dataIndex: 'kategorie',
      key: 'kategorie',
    },
   
    {
      title: 'Grund',
      dataIndex: 'grund',
      key: 'grund',
      render: (text) => (
        <Tooltip title={text}>{text.length > 25 ? `${text.slice(0, 25)}...` : text}</Tooltip>
      ),
    },
    {
      title: 'Betroffene Benutzer*innen',
      dataIndex: 'betroffenebenutzersupportfalls',
      key: 'betroffenebenutzersupportfalls',
      render: (betroffenebenutzersupportfalls) => (
        <>
          {betroffenebenutzersupportfalls && betroffenebenutzersupportfalls.map((user) => (
            <Tag key={user.id} color="blue"  style={{ marginBottom: '4px'}}>
              {user.benutzer && user.benutzer.benutzername}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Bearbeiter*innen',
      dataIndex: 'bearbeitersupportfalls',
      key: 'bearbeitersupportfalls',
      render: (bearbeitersupportfalls) => {
        console.log(bearbeitersupportfalls);
        return (
          <>
            {bearbeitersupportfalls && bearbeitersupportfalls.map((user) => (
              <Tag key={user.benutzer.id} color="purple"  style={{ marginBottom: '4px'}}>
                {user.benutzer && user.benutzer.benutzername}
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: 'Melder*innen',
      dataIndex: 'meldersupportfalls',
      key: 'meldersupportfalls',
      render: (meldersupportfalls) => (
        <>
          {meldersupportfalls && meldersupportfalls.map((user) => (
            <Tag key={user.benutzer.id} color="default" style={{ marginBottom: '4px'}}>
              {user.benutzer && user.benutzer.benutzername}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Aktionen',
      key: 'actions',
      render: (text, record) => (
        <Row gutter={[8, 8]}>
        <Col> 
        <Link to={`/support/${record.id}/ansicht`}>
            <Button type="default" >
              Ansicht
            </Button>
            </Link>
            </Col>
            {(record.status !== 'geschlossen' || hatUserRolle('S-Admin') )&& ( 
        <Col>
        <Link to={`/support/${record.id}`}>
            <Button type="primary">
              Bearbeiten
            </Button>
            </Link>
            </Col>
             )}
      </Row>
      ),
    },
  ];
  useEffect(() => {
    
    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.alleSupporterfaelleZuBenutzer}/${userId}`,  { 
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `${token}` : '',
    },
  })
      .then(response => response.json())
      .then(data => {
        setSupportfaelle(data);
        setFilteredSupportfaelle(data.slice(0, pageSize)); 
      })
      .catch(error => console.error('Fehler beim Abrufen der Daten:', error));
  }, []); 



  return (
         
       <Space direction="vertical" size="middle" style={{ display: 'flex', color: 'white', marginTop: 50, width: '100%' }}>

  <Divider orientation="left" style={{ color: "white", borderColor: 'white', fontSize: 22, width: '100%' }}>
    Benutzer - {benutzername}
  </Divider>
  
  <Link to="#" onClick={onBackLinkClick} style={{ fontSize: '14px', color: '#fff', textDecorationLine: 'none' }} >
      <LeftOutlined style={{ fontSize: '14px', color: '#fff', verticalAlign: 'middle' }} /> zurück
    </Link>
    {banned && (
        <div style={{ padding: '16px', border: '1px solid rgb(255, 172, 1)', borderRadius: '8px', background: 'rgba(255,172,1, 0.1)', marginTop: '16px' }}>
          <InfoCircleOutlined style={{ color: '#ffac01', marginRight: '8px' }} />
          Dieser Benutzer ist aktuell bis {gebanntBis} gesperrt.
        </div>
      )}
       {perma && (
        <div style={{ padding: '16px', border: '1px solid rgb(255, 172, 1)', borderRadius: '8px', background: 'rgba(255,172,1, 0.1)', marginTop: '16px' }}>
          <InfoCircleOutlined style={{ color: '#ffac01', marginRight: '8px' }} />
          Dieser Benutzer ist permanend gesperrt.
        </div>
      )}
      {klaerung && (
        <div style={{ padding: '16px', border: '1px solid rgb(255, 172, 1)', borderRadius: '8px', background: 'rgba(255,172,1, 0.1)', marginTop: '16px' }}>
          <InfoCircleOutlined style={{ color: '#ffac01', marginRight: '8px' }} />
          Dieser Benutzer ist aktuell bis zur Klärung gesperrt.
        </div>
      )}

  <Form layout='horizontal' style={{ background: `transparent`, width: '30%' }}
    labelCol={{
      span: 8,
    }}
    wrappercol={{
      span: 16,
    }}
    labelAlign='right' >
    
    
    <Form.Item className='item' flex="150px" label="Benutzername">
      <Input maxLength={35} value={benutzername} disabled={!hatUserRolle('S-Admin')} className='input' placeholder="Benutzername" onChange={onChangeBenutzername}></Input>
    </Form.Item>
    {hatUserRolle('S-Admin') && (
      <>
    <Form.Item className='item' label="Team">
      <Input maxLength={35} value={abteilung}  className='input' placeholder="Abteilung" onChange={(inputValue) => setAbteilung(inputValue.target.value)}></Input>
    </Form.Item>
    
  <Form.Item label='Rolle' className='item' >
  <Select
    mode="multiple"
    value={rang}  
    placeholder="Wählen Sie eine Rolle aus"
    options={rollen}
    onChange={onChangeRollen}
  />
</Form.Item>

</>
    )}
  </Form>
 
  <CharakterListe key='chars' charaktere={charakter} />
  {hatUserRolle('S-Admin') && (
      <Button type="primary" htmlType="submit" className='button' onClick={handleBearbeiten} style={{ float: 'right' }}>Benutzer bearbeiten</Button>
  )}

  
<Row>
          <Col flex="auto">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalPages * pageSize}
              onChange={handlePageChange}
              style={{ marginTop: 16, textAlign: 'center', marginBottom: 16 }}
            />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
          
          <Table dataSource={filteredSupportfaelle} columns={columns} pagination={false}  rowKey={(record) => record.id} style={{marginBottom: '14px'}} />
          
          </Col>
        </Row>


</Space>
     
        
  );
};

export default BenutzerInfo;
import { Layout, Menu } from "antd";
import Login from "./Login";
import Logo from './Logo';
import { useLocation, Routes , Route, Navigate, Link } from 'react-router-dom';
import BenutzerInfo from "./BenutzerInfo";
import { authMiddleware } from './authMiddleware';
import SupportfallErstellen from "./SupportfallErstellen";
import SupportfallBearbeiten from "./SupportfallBearbeiten";
import ItemsAnlegen from "./ItemsAnlegen";


const { Sider } = Layout;

const startseiteRoute = authMiddleware('Startseite');
const scpRoute = authMiddleware('ServerControlPanel');
const benutzerRoute = authMiddleware('Benutzer');
const supportRoute = authMiddleware('Support');
// const GameDesignRoute = authMiddleware(GameDesign, ['Game-Design']);
// const LogRoute = authMiddleware(Log, ['Development']);
//onst GameDesignDevelopmentRoute = authMiddleware(, ['Development', 'Game-Design']);
//const SuperAdminRoute = authMiddleware(SuperAdminComponent, ['S-Admin']);


function Root ()
{
  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  if (!isLoginPage) {
    if (startseiteRoute === null && benutzerRoute === null && supportRoute === null) {
      return <Navigate to="/" />;
    }
  }

    return (

    <div className="bg-layer">

      <div className="w-full h-full bg-darkpurple/50 overflow-auto">
      
      <Layout hasSider className="layout" style={{ minHeight: '96vh', flexDirection:"row",  margin: 'auto', marginTop: '2vh' , marginBottom: '2vh', marginLeft: '2vh' , marginRight: '2vh' ,marginBottom: !isLoginPage ? '2vouth' : '2vh', 
        backgroundColor: !isLoginPage ? 'rgba(0,0,0,0.8)' : 'transparent', 
        borderRadius: !isLoginPage ? '10px' : '0', paddingRight: '50px', paddingLeft: '50px' }}>
        
          {!isLoginPage && (
            
        <Sider width={270} className="sidebar">
          <Logo className="logo" />
          <div >
        <Menu className='menu' mode='inline' > 
        {startseiteRoute !== null && (     
        <Menu.Item  className='menu' key="startseite"><Link className='divPage' to='/startseite'>Startseite</Link></Menu.Item>)}
        {scpRoute !== null && (     
        <Menu.Item  className='menu' key="scp"><Link className='divPage' to='/server-control-panel'>Server Control Panel</Link></Menu.Item>)}
        {benutzerRoute !== null && (  
        <Menu.Item  className='menu' key="benutzer"><Link className='divPage' to='/benutzer'>Benutzer</Link></Menu.Item>)}
        
        <Menu.Item  className='menu' key="Items"><Link className='divPage'  to='/itemAnlegen'>Items</Link></Menu.Item>
        {supportRoute !== null && (  
          <>
        <Menu.Item  className='menu' key="support"><Link className='divPage' to='/support'>Support</Link></Menu.Item>
        <Menu.Item  className='menu' key="supportfallErstellen"><Link className='divPage' to='/supportfallErstellen'>Supportfall erstellen</Link></Menu.Item>
        </> )}
        
        <Menu.Item  className='menu' key="registrierung"><Link className='divPage' to='/registrierung'>Registrierung</Link></Menu.Item>
      
            
        </Menu>
        </div>
        </Sider>
      
          )}
          <AppContent/>

      </Layout>

      </div>

  </div>

    );


}

function AppContent()
{
  return (
    <Routes>
    {/* Geschützte Route für die Startseite */}
    <Route path="/" element={<Login/>} />
     
    {startseiteRoute !== null && (     
      <Route path="/startseite" element={startseiteRoute} />
    )}

    {scpRoute !== null && (     
      <Route path="/server-control-panel" element={scpRoute} />
    )}
  
    {benutzerRoute !== null && (
      <>
        <Route path="/benutzer" element={benutzerRoute} />   
        <Route path="/benutzerinfo/:userId" element={<BenutzerInfo style={{ padding: '0 24px', width: '100%' }} />} />
      </>
    )}
   {supportRoute !== null && (  
    <>
     <Route path="/support" element={supportRoute} />  
     <Route path="/supportfallErstellen" element={<SupportfallErstellen style={{ padding: '0 24px', width: '100%' }} />} />
     <Route path="/support/:id" element={<SupportfallBearbeiten mode="edit" style={{ padding: '0 24px', width: '100%' }} />} />
     <Route path="/support/:id/ansicht" element={<SupportfallBearbeiten mode="view" style={{ padding: '0 24px', width: '100%' }} />} />
   
   </>)}
   <Route path="/itemAnlegen" element={<ItemsAnlegen  style={{ padding: '0 24px', width: '100%' }} />}  />
   
  </Routes>
)};



export default Root;

import React from "react";
import logo from './nelion_final2.png'
const Logo = () => {
    return ( 
        <div className="logo">
            <div className="logo-icon"></div> 
            <a className="navbar-brand" href="/"><img src={logo} width="150" height="150" className="d-inline-block align-top" alt=""/></a>

        </div> 
            );
};

export default Logo;
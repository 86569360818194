import React from 'react';

function Inventar({ x, y , brightBackground, uploadedFile }) {
    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',

        padding: '2px',
        marginBottom: '20px',
    };
    const groesseItemx = 75 * x;

    const groesseItemy = 75 * y;
    console.log(groesseItemx,groesseItemy);
    
    const backgroundImageStyle = {
        backdropFilter: 'blur(5px)',
        background: ' rgba(0, 0, 0, 0.75)',
        position: 'absolute',
        padding: '4px',
        placeSelf: 'center',
        width: groesseItemy + 'px', 
        height: groesseItemx + 'px', 
        objectFit: 'contain',
        
    };
    const backgroundImage = uploadedFile && uploadedFile instanceof File && URL.createObjectURL(uploadedFile);
    console.log(backgroundImage);

    const renderGrid = () => {
        const gridItems = [];
        const backgroundColor = brightBackground ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
        for (let i = 0; i < x; i++) {
            for (let j = 0; j < y; j++) {
                gridItems.push(
                    <div
                        key={`${i}-${j}`}
                        style={{
                            width: '75px',
                            height: '75px',
                            border: '0.5px solid rgba(0, 0, 0, 0.5)',
                            backgroundColor,
                            
                           
                        
                        }}
                    ></div>
                );
            }
            // Füge eine leere div hinzu, um den Zeilenumbruch zu erzwingen
            gridItems.push(<div key={`row-${i}`} style={{ flexBasis: '100%', height: '0' }} />);
        }
        return gridItems;
    };

    return (
       
            <div style={{
              
                display: 'flex',
                
            }}>   
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >  
                {renderGrid()}
               
                <img src={backgroundImage} alt=" " style={backgroundImageStyle}  />
                
                </div>
                
            </div>
       
    );
}

export default Inventar;

import React, { useState , useEffect } from 'react';
import { Form, Input, Select, DatePicker, Button, Space, message, Divider, List, Avatar, Modal } from 'antd';
import { LeftOutlined,UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import serverConfig from '../config';
import moment from 'moment';
import 'moment/locale/de';
import dayjs from 'dayjs'

const SupportGespraechModal = ({  onSave, benutzer, supporter,  bearbeitungsWerte}) => {
    const [form] = Form.useForm();
    const [selectedBenutzer, setSelectedBenutzer] = useState([]);
    const [selectedSupport, setSelectedSupport] = useState([]);
    const [selectedInhalt, setSelectedInhalt] = useState('');
    const [selectedErstelltAm, setSelectedErstelltAm] = useState();
    
    
    useEffect(() => {
        if (bearbeitungsWerte) {
            if (bearbeitungsWerte.selectedUsers) {
                console.log(bearbeitungsWerte.selectedUsers);
            setSelectedBenutzer(bearbeitungsWerte.selectedUsers);
        }
        if (bearbeitungsWerte.selectedSupporter) {
            setSelectedSupport(bearbeitungsWerte.selectedSupporter);
        } 
        if (bearbeitungsWerte.inhalt) {
            setSelectedInhalt(bearbeitungsWerte.inhalt);
        } 
        if (bearbeitungsWerte.erstelltAm) {
            console.log(bearbeitungsWerte.erstelltAm);
            
            setSelectedErstelltAm(dayjs(bearbeitungsWerte.erstelltAm , 'DD.MM.YYYY HH:mm'));
            console.log(selectedErstelltAm);
        } 
    }
    }, [bearbeitungsWerte]);
    

    const handleOk = () => {
        try {
            const gespraech = {
                            erstelltAm: dayjs(selectedErstelltAm, 'DD.MM.YYYY HH:mm'),
                            inhalt: selectedInhalt,
                            selectedUsers: selectedBenutzer,
                            selectedSupporter: selectedSupport,
                        };
            onSave(gespraech);
            setSelectedErstelltAm(dayjs());
            setSelectedInhalt('');
            setSelectedBenutzer([]);
            setSelectedSupport([]);
            form.resetFields();
          } catch (errorInfo) {
            console.log('Fehler beim Speichern des Zeugen:', errorInfo);
          }
        };
        const handleDateChange = (date, dateString) => {
            setSelectedErstelltAm(dateString);
        };
        const handleInhaltChange = (e) => {
            setSelectedInhalt( e.target.value);
        };
        const handleSelectedUsersChange = (id) => {
           
            setSelectedBenutzer(id);
        };
        const handleSelectedSupporterChange = (id) => {
            setSelectedSupport(id);
        };

    return (
     
            <Form form={form} layout="vertical" onFinish={handleOk}>
                <Form.Item label="Gesprächsdatum und -zeit">
                    <DatePicker
                        value={selectedErstelltAm ? dayjs(selectedErstelltAm, 'DD.MM.YYYY HH:mm') : dayjs()}
                        format={'DD.MM.YYYY HH:mm'}
                        showTime={{format: 'HH:mm'}}
                        onChange={handleDateChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Gesprächsinhalt"
                    rules={[{ required: true, message: 'Bitte den Inhalt eingeben' }]}
                    >
                    <Input.TextArea
                        placeholder="Protokoll eingeben"
                        rows={4}
                        value={selectedInhalt}
                        onChange={handleInhaltChange}
                        
                    />
                    </Form.Item>
                <Form.Item label="Anwesende Benutzer*innen">
                    <Select
                        mode="multiple"
                        placeholder="Anwesende Benutzer*innen auswählen"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedBenutzer}
                        options={benutzer}
                        onChange={handleSelectedUsersChange}
                    />
                </Form.Item>
                <Form.Item label="Supporter*innen">
                    <Select
                        mode="multiple"
                        placeholder="Supporter*innen auswählen"
                        
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedSupport}
                        options={supporter}
                        onChange={handleSelectedSupporterChange}
                    />
                </Form.Item>
                <Button type="primary" htmlType="button" style={{ float: 'right', marginBottom: '6px' }} onClick={handleOk}>
          Supportgespräch speichern
        </Button>
            </Form>
      
    );
};
export default SupportGespraechModal;
import { Input, Form, Modal, Checkbox, Button, Carousel } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import serverConfig from "../config";
import { useNavigate } from "react-router-dom";
//import { setCookie } from './Cookies';
import logo from "./assets/img/logo_g.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const navigate = useNavigate();
  const [benutzername, setBenutzername] = useState("");
  const [passwort, setPasswort] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const onFinish = async (values) => {
    const updatedData = {
      benutzername: benutzername,
      passwort: passwort,
    };

    fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.anmelden}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // Fügen Sie hier ggf. zusätzliche Header hinzu
      },
      body: JSON.stringify(updatedData),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Login erfolgreich") {
          console.log(data);
          localStorage.setItem("token", data.token);
          navigate("/startseite");
        } else {
          setShowErrorModal(true);
        }
      })
      .catch((error) => {
        console.error("Fehler beim Aktualisieren der Datenbank:", error);
      });
  };

  const onChangePasswort = (input) => {
    setPasswort(input.target.value);
  };

  const onChangeBenutzername = (input) => {
    setBenutzername(input.target.value);
  };

  return (
    <div className="flex w-full h-[575px] place-self-center place-content-center place-items-center">
      <div className="p-4 bg-darkpurple/95 backdrop-blur-sm min-w-[390px] w-[25%] h-full">
        <Form
          onFinish={onFinish}
          labelCol={{ span: 20 }}
          wrappercol={{ span: 22 }}
        >
          <div className="logo mb-4">
            <div className="logo-icon"></div>
            <a className="navbar-brand" href="/">
              <img
                src={logo}
                height="85"
                className="d-inline-block align-top opacity-25"
                alt=""
              />
            </a>
          </div>

          <div>
            <h1 className="font-oxanium font-extrabold m-unset">EINLOGGEN</h1>
            <p className="text-yellow font-poppins">
              Erlebe atemberaubende Geschichten auf Nelion!
            </p>
          </div>

          <Form.Item
            className="item mb-2"
            name="benutzename"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie Ihren Benutzernamen ein!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              allowClear
              placeholder="Benutzername"
              className="input !bg-white/5 border-0 rounded-none text-base p-[10px]"
              onChange={onChangeBenutzername}
              maxLength={40}
            />
          </Form.Item>

          <Form.Item
            name="passwort"
            className="mb-4"
            rules={[
              { required: true, message: "Bitte geben Sie Ihr Passwort ein!" },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Passwort"
              className="input !bg-white/5 border-0 rounded-none text-base p-[10px]"
              onChange={onChangePasswort}
            />
          </Form.Item>

          <Form.Item className="m-unset mb-3">
            <Button
              className="text-lg h-[40px] !bg-gradient-to-r from-purple to-yellow border-0 text-white rounded-0 w-full font-poppins shadow-none"
              htmlType="submit"
            >
              Login
            </Button>
          </Form.Item>

          <p className="text-center mb-3 flex items-center justify-center">
            <span className="flex-1 border-t border-lightgray/25"></span>
            <span className="px-4">ODER</span>
            <span className="flex-1 border-t border-lightgray/25"></span>
          </p>

          <Form.Item className="m-unset">
            <Button className="text-lg h-[40px] !bg-discord border-0 text-white rounded-0 w-full font-poppins shadow-none">
              <FontAwesomeIcon icon={faDiscord} className="mr-2" />
              Login über Discord
            </Button>
          </Form.Item>
          <Modal
            title="Fehler beim Login"
            visible={showErrorModal}
            onOk={() => setShowErrorModal(false)}
            onCancel={() => setShowErrorModal(false)}
          >
            <p>
              Benutzername oder Passwort ungültig. Bitte überprüfen Sie Ihre
              Eingaben.
            </p>
          </Modal>
        </Form>

        <div className="place-content-center flex mt-4 p-[10px]">
          <p className="text-center place-self-center font-poppins text-xs font-semibold text-lightgray">
            Du hast noch keinen Account?
            <br />
            <Link className="text-yellow hover:text-purple" to="/">
              Jetzt Registrieren
            </Link>{" "}
            {/* TODO: Registrierungslink einbinden */}
          </p>
        </div>
      </div>

      <div className="bg-darkpurple/95 place-self-center w-[60%] h-full hidden xl:block">
        <Carousel
          autoplay
          autoplaySpeed={10000}
          dots={false}
          effect="fade"
          className="w-full h-full"
        >
          <div className="slider-1 w-full h-full">
            <div className="bg-darkpurple/50 w-full h-full z-1 absolute backdrop-blur-sm"></div>
            <div className="slider-1-content w-full h-full"></div>
            <p className="absolute z-3 top-3 right-3 w-[220px] text-midgray font-medium text-base">
              In San Andreas gibt es vielfältige Wege, um Geld zu verdienen,
              doch einige bergen Risiken jenseits des Gesetzes. Von legalen
              Geschäften bis hin zu zwielichtigen Unternehmungen bietet Los
              Santos und die Umgebung ein breites Spektrum an
              Verdienstmöglichkeiten.
            </p>
          </div>
          <div className="slider-2 w-full h-full">
          <div className="bg-darkpurple/50 w-full h-full z-1 absolute backdrop-blur-sm"></div>
            <div className="slider-2-content w-full h-full"></div>
            <div className="absolute z-3 top-3 right-3 w-[320px]">
              <p className="text-midgray font-medium text-base">
              Du führst eine Fraktion und willst ein eigenes MLO oder andere Assets auf den Server bringen?
              Der Prozess ein Asset auf den Server zu bringen kann aufgrund von Performance- und Qualitätschecks etwas Zeit in Anspruch nehmen.
              Unser Partner Pleb Masters bietet allerdings Assets die unseren Anforderungen entsprechen, welches unsere Prozesse beschleunigen kann.
              Schaue gerne beim Pleb Masters Store vorbei!
              </p>
              <a href="https://store.plebmasters.de" className="text-yellow font-medium text-base no-underline w-full">
                      Zum Pleb Masters Store
                <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
              </a> 
            </div> 
            <div className="absolute z-3 bottom-5 right-5 w-[300px] opacity-50">
              <img
                src="assets/img/slider/plebmastersxnelion.png"
                alt="Plebmasters"
                className="reg-slide01-content-img"
              />
            </div>
          </div>
          {/* Weitere Slides hier einfügen */}
        </Carousel>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, useEffect} from 'react';
import { Form, Input, Button, Checkbox, message, Select , Space , Divider , Row , Col, Card, Radio, Typography,Modal, InputNumber , Slider, Switch, Upload} from 'antd';
import { LeftOutlined, InboxOutlined , MoonOutlined , SunOutlined  } from  '@ant-design/icons';
import { Link , useNavigate } from 'react-router-dom';
import Inventar from './Inventar';
import serverConfig from '../config';
const { Dragger } = Upload;
const { Text } = Typography;
const { Option } = Select;


const ItemsAnlegen = () => {
  const token = localStorage.getItem('token');
  if(!token)
  {
    navigate('/');
  }
  const [itemName, setItemName] = useState('');
  const [note, setNote] = useState('');
  const [x, setX] = useState(1);
  const [y, setY] = useState(1);
  const [visableX, setVisableX] = useState(false);
  const [visableY, setVisableY] = useState(false);
  const [stack, setStack] = useState(6);
  const [gewicht, setGewicht] = useState(2);
  const [essen, setEssen] = useState(0);
  const [essenKategorie, setEssenKategorie] = useState('Gastro');
  const [haltbarkeit, setHaltbarkeit] = useState(1); 
  const [trinken, setTrinken] = useState(0);
  const [drogen, setDrogen] = useState(0); 
  const [alkohol, setAlkohol] = useState(0); 
  const [brightBackground, setBrightBackground] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedSlotTypes, setSelectedSlotTypes] = useState([]);
  const [selectedItemTypes, setSelectedItemTypes] = useState([]);
  const [effekt, setEffekt] = useState([]);
  const [kategorie, setKategorie] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isBeschreibbarMehrzeilig, setIsBeschreibbarMehrzeilig] = useState(false);
  const [anzahlZeilen, setAnzahlZeilen] = useState(1); 
  const navigate = useNavigate();
  const options = [ 
    {
      label: 'Gastro',
      value: 'Gastro',
    },
    {
      label: 'Zutat',
      value: 'zutat',
    },
    {
      label: '24/7',
      value: '24/7',
    },
  ];
  const effekte = [ 
    {
      label: 'keiner',
      value: '1',
    },
    
  ];


  const onFinish = () => {
    if(kategorie !== 'Effekt')
    {
      const data = {
        name: itemName,
        gewicht: gewicht,
        stack: stack,
        x: x, 
        y: y,
        icon : URL.createObjectURL(uploadedFile),
        effekt: 1, 
        slotStandard: selectedSlotTypes.includes("slotStandard"),
        slotBrieftasche: selectedSlotTypes.includes("slotBrieftasche"),
        slotAusruestung: selectedSlotTypes.includes("slotAusruestung"),
        slotKleidung: selectedSlotTypes.includes("slotKleidung"),
        slotTasche: selectedSlotTypes.includes("slotTasche"),
        weitergeben: selectedProperties.includes("weitergeben"),
        platzierbar : selectedProperties.includes("platzierbar"),
        benutzbar : selectedProperties.includes("benutzbar"),
        beschreibbarEinmal : selectedProperties.includes("beschreibbarEinmal"),
        benutzbarInFahrzeugen: selectedProperties.includes("benutzbarInFahrzeugen"),
        beschreibbarMehrmals : selectedProperties.includes("beschreibbarMehrmals"),
        notiz : note,
        typKategorie: kategorie
      }
      if(kategorie === 'Essen')
      {
        data.drogen =  drogen;
        data.alkohol = alkohol;
        data.essen = essen;
        data.trinken = trinken;
        data.typ = essenKategorie.target.value;
        data.haltbarkeit = haltbarkeit;
      
      }
      if(kategorie === 'Dokument')
      {
          data.isBeschreibbarMehrzeilig = isBeschreibbarMehrzeilig;
          data.anzahlZeilen = anzahlZeilen;
          
      }
      
      try {
        const response =  fetch(`${serverConfig.baseUrl}${serverConfig.endpoints.itemAnlegenEssen}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `${token}` : '',
          },
          body: JSON.stringify(data),
        });
            
          message.success('Item erfolgreich erstellt!');
          navigate('/startseite');
        
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Datenbank:', error);
        message.error('Fehler beim Erstellen des Items');
      }
    }
    
  };

  const handleCheckboxChange = checkedValues => {
    // Überprüfe, ob "beschreibbar (mehrzeilig)" ausgewählt ist
    if (checkedValues.includes('beschreibbarMehrzeilig')) {
        setIsBeschreibbarMehrzeilig(true);
    } else {
        setIsBeschreibbarMehrzeilig(false);
    }
};
  const onBackLinkClick = () => {
    navigate(-1);
  };

  
  const validateX = (_, value) => {
    if (value < 0) {
      return Promise.reject(new Error('X darf nicht kleiner als 0 sein!'));
    }
    return Promise.resolve();
  };

  const validateY = (_, value) => {
    if (value < 0) {
      return Promise.reject(new Error('Y darf nicht kleiner als 0 sein!'));
    }
    return Promise.resolve();
  };

  const validateStack = (_, value) => {
    if (value < 0) {
      return Promise.reject(new Error('Stack darf nicht kleiner als 0 sein!'));
    }
    return Promise.resolve();
  };

  const handlePropertyChange = (checkedValues) => {
    setSelectedProperties(checkedValues);
  };

  const handleSlotTypeChange = (checkedValues) => {
    setSelectedSlotTypes(checkedValues);
  };

  const handleItemTypeChange = (checkedValues) => {
    setSelectedItemTypes(checkedValues);
  };
  const handleSliderChange = (value) => {

    
    console.log(value[1]);

     if (value[0] !== 0 && value[1] !== 0) {
        if (essen[0] === 0) {
          value[1] = 0;
        } else if (essen[1] === 0) {
          value[0]= 0;
        }
    }
    
    setEssen(value);
   
  };
  const handleSliderChangeTrinken = (value) => {

    
    console.log(value[1]);

     if (value[0] !== 0 && value[1] !== 0) {
        if (essen[0] === 0) {
          value[1] = 0;
        } else if (essen[1] === 0) {
          value[0]= 0;
        }
    }
    
    setTrinken(value);
  } 

  const toggleBackground = () => {
    setBrightBackground(!brightBackground);
};

//upload
const handleFileUpload = (info) => {
  if (info.file.length > 1) {
    message.error('Es kann nur eine Datei auf einmal hochgeladen werden');
   
    return;
}
  if (info.file.status === 'done') {
      message.success(`${info.file.name} wurde erfolgreich hochgeladen.`);
      // Setze den Dateipfad des hochgeladenen Bildes
      setUploadedFile(info.file.originFileObj);
  } else if (info.file.status === 'error') {
      message.error(`${info.file.name} konnte nicht hochgeladen werden.`);
  }
};
const customUploadRequest = ({ file, onSuccess, onError }) => {
  setTimeout(() => {
    if (file.length > 1) {
      message.error('Es kann nur eine Datei auf einmal hochgeladen werden');
    
      return;
  }
      onSuccess();
  }, 1000);
};

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex', color: 'white', marginTop: 50, width: '100%' }}>
  
    <Divider orientation="left" style={{ color: 'white', borderColor: 'white', fontSize: 22, width: '100%' }}>
    Item anlegen
    </Divider>
    <Link to="#" onClick={onBackLinkClick} style={{ fontSize: '14px', color: '#fff', textDecorationLine: 'none' }}>
      <LeftOutlined style={{ fontSize: '14px', color: '#fff', verticalAlign: 'middle' }} /> zurück
    </Link>
      <Form style={{ background: `transparent`, width: '75%' }}
        name="createItemForm"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
          wrappercol={{ span: 16 }}
          labelAlign='right' 
      >
         <Row gutter={[16, 0]}>
          <Col span={16}>
        <Form.Item
          label="Name"
          name="itemName"
          rules={[{ required: true, message: 'Bitte geben Sie einen Namen für das Item ein!' }]}
        >
          <Input onChange={(e) => setItemName(e.target.value)} />
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={[16, 0]}>
        <Col span={16}>
        <Form.Item label="Notiz" name="note">
          <Input.TextArea onChange={(e) => setNote(e.target.value)} />
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={16}>
        <Form.Item
          label="Kategorie"
          name="kategorie"
        >
          <Select onChange={(e) => setKategorie(e)} >
            <Option value="Dokument">Dokument</Option>
            <Option value="Geld">Geld</Option>
            <Option value="Effekt">Effekt</Option>
            <Option value="Essen">Essen</Option>
            <Option value="Getränk">Getränk</Option>
            <Option value="Medikamente">Medikamente & Drogen</Option>
            <Option value="Tasche">Tasche</Option>
        </Select>
        </Form.Item>
        </Col>
        </Row>

      

        { kategorie !== 'Effekt' && (
          <>
       
        <Row gutter={[16, 0]}>
          <Col className="custom-offset" span={8}>
        <Form.Item label="X" name="x" rules={[{ validator: validateX }]}>
          {/*<Input type="number"  style={{ width: '100%' }} onChange={(e) => setX(parseInt(e.target.value))} /> */}
          <Radio.Group  onChange={(e) => {setX(e.target.value); if(e.target.value === 6) setVisableX(true);}} defaultValue={1} optionType="button"
              buttonStyle="solid">
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={4}>4</Radio>
                  <Radio value={6}>mehr</Radio>  
                </Radio.Group>
        </Form.Item>
        </Col>
          <Col span={8}>
        <Form.Item label="Y" name="y" rules={[{ validator: validateY }]}>
            {/*<Input type="number" style={{ width: '100%' }} onChange={(e) => setY(parseInt(e.target.value))} />*/}
            <Radio.Group  onChange={(e) => {setY(e.target.value); if(e.target.value === 6) setVisableY(true);}} defaultValue={1} optionType="button"
              buttonStyle="solid">
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={4}>4</Radio>
                  <Radio value={6}>mehr</Radio>   
                </Radio.Group>
                
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={[16, 0]}>
        <Col className="custom-offset-stack">
        <Form.Item label="Stack" name="stack" rules={[{ validator: validateStack }]}>
          <Input type="number" defaultValue={stack} style={{ width: '100%' }} onChange={(e) => setStack(e.target.value)} />
        </Form.Item>
        </Col>
        </Row>
       
          <Row gutter={[24, 0]}>
            <Col className="custom-offset-inventar">
          <Form.Item>
        <Switch   checked={brightBackground} onChange={toggleBackground} style= {{marginBottom: '20px' }} checkedChildren={<SunOutlined />}
      unCheckedChildren={<MoonOutlined />}/>
        <Inventar x={x} y={y} brightBackground={brightBackground} uploadedFile={uploadedFile}>
          </Inventar>
       </Form.Item>
        </Col>
        </Row> </>)}
        <Row gutter={[24, 0]}>
            <Col className="custom-offset-auswahl">
            <Text>Deine Auswahl: {x} x {y} <br/></Text>
          <Form.Item style={{ marginLeft: '22%'}}>
        <Upload.Dragger className="custom-offset-auswahl" 
                    name="file"
                    multiple={false}
                    action="/upload"
                    maxCount={1}
                    onChange={handleFileUpload}
                    accept="image/*"
                    customRequest={customUploadRequest}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Klicke oder ziehe eine Datei hierher, um sie hochzuladen</p>
                </Upload.Dragger>
              
       </Form.Item>
        </Col>
        </Row> 
        
        { kategorie !== 'Effekt' && ( <>
    
        <Row gutter={[16, 0]}>

    <Text className="custom-offset-gewicht">Gewicht (in kg):</Text>
    <div style={{ width: '400px' ,  marginLeft: '5px'}}> 
      <Slider
        min={0}
        max={20}
        onChange={(value) => setGewicht(value)} 
        value={typeof gewicht === 'number' ? gewicht : 0}
        step={0.1}
      />
    </div>
  
  
    <InputNumber
      min={0}
      max={20}
      style={{ margin: '0 16px' , marginBottom: '10px' }}
      step={0.1}
      value={gewicht}
      onChange={(value) => setGewicht(value)} 
    />
  
</Row></>)}
{ kategorie === 'Essen' && (
<>
        <Row gutter={[16, 0]}>
      <Col span={16}>
      <Form.Item name='essen' label='Essen' >
      <InputNumber
      min={-100}
      max={100}
      step={0.10}
      defaultValue={essen}
      onChange={(value) => setEssen(value)} 
    /><Text  style={{ margin: '0 16px' , marginBottom: '10px' }}>von -100 bis max 100</Text>
          </Form.Item>


        
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
      <Col span={16}>
      
          
          <Form.Item name='trinken' label='Trinken' >
          <InputNumber
            min={-100}
            max={100}
            step={0.10}
            defaultValue={trinken}
            onChange={(value) => setTrinken(value)} 
          />
          <Text  style={{ margin: '0 16px' , marginBottom: '10px' }}>von -100 bis max 100</Text>
          </Form.Item>

    
        </Col>
 </Row>
 <Row gutter={[16, 0]}>
      <Col span={16}>
      
          
          <Form.Item name='Alkohol' label='Alkohol' >
          <InputNumber
            min={-100}
            max={100}
            step={0.10}
            defaultValue={alkohol}
            onChange={(value) => setAlkohol(value)} 
          />
          <Text  style={{ margin: '0 16px' , marginBottom: '10px' }}>von -100 bis max 100</Text>
          </Form.Item>
          
        
        </Col>
    
 </Row>
 <Row gutter={[16, 0]}>
      <Col span={16}>
      
          
          <Form.Item name='Drogen' label='Drogen' >
          <InputNumber
            min={-100}
            max={100}
            step={0.10}
            defaultValue={drogen}
            onChange={(value) => setDrogen(value)} 
          />
          <Text  style={{ margin: '0 16px' , marginBottom: '10px' }}>von -100 bis max 100</Text>
          </Form.Item>

        
        </Col>
    
 </Row>
 <Row gutter={[16, 0]}>
      <Col span={16}>
      <Form.Item name='Haltbarkeit' label='Haltbarkeit' >
      <InputNumber
      min={0}
      max={100}
     
      step={1}
      defaultValue={haltbarkeit}
      onChange={(value) => setHaltbarkeit(value)} 
    />
    <Text  style={{ margin: '0 16px' , marginBottom: '10px' }}>in Tagen</Text>
          </Form.Item>


        
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={16} >
        <Form.Item  name="Typ" label="Typ">
    
        <Radio.Group
        options={options}
        onChange={(value) => setEssenKategorie(value)}
        value={essenKategorie}
        optionType="button"
        buttonStyle="solid"
      />
        </Form.Item>
        </Col>
        </Row>
    
  </> )}


   { kategorie !== 'Effekt' && (
        <Row gutter={[16, 0]}>
          <Col span={16}>
        <Form.Item
          label="Effekt"
          name="effekt"
        >
          <Select options={effekte} onChange={(e) => setEffekt(e)} />
        </Form.Item>
        </Col>
        </Row>

   )}


        <Row gutter={[16, 0]}>
        <Col span={16} className="custom-offset-dropdown">
        <Form.Item name="properties">
        <Card title="Optionen" style={{ whiteSpace: 'nowrap' }}>
       
            <Checkbox.Group onChange={handlePropertyChange}>
               <Row>
                   <Col span={24}>
                        <Checkbox value="weitergeben">weitergeben</Checkbox> <br />
                       <Checkbox value="platzierbar">platzierbar</Checkbox><br />
                       
                        </Col>
                        </Row>
                        <Row>
                        <Col span={24}>
                        <Checkbox value="benutzbar">benutzbar</Checkbox><br />
                         {/* <Checkbox value="publicSteal">public steal</Checkbox><br /> */}
                         <Checkbox value="beschreibbarEinmal">beschreibbar (einmal)</Checkbox> <br />
                        
                        </Col>
                        </Row>
                        <Row>
                        <Col span={24}>
                        <Checkbox value="benutzbarInFahrzeugen">benutzbar in Fahrzeugen</Checkbox><br />
                        <Checkbox value="beschreibbarMehrmal">beschreibbar (mehrmals)</Checkbox> <br />
                       
                         {/*<Checkbox value="limitedSteal">limited steal</Checkbox><br /> 
                        <Checkbox value="keineAhnung">...</Checkbox>*/}
                        </Col>
                        </Row>
                       
                
            </Checkbox.Group>
            { kategorie == 'Dokument' && ( <>
          <Row gutter={[16, 0]}>
          <Col  span={8}>
                        <Checkbox.Group onChange={handleCheckboxChange}>
                <Checkbox value="zeigbar" >zeigen</Checkbox>
                <Checkbox value="beschreibbarMehrzeilig" style={{ marginBottom: '10px'}}>beschreibbar (mehrzeilig)</Checkbox>
            </Checkbox.Group>
            {isBeschreibbarMehrzeilig && (  <>
            <br />
                    <InputNumber style={{ marginBottom: '10px'}}
                        min={1}
                        defaultValue={1}
                        max={255}
                        onChange={value => setAnzahlZeilen(value)} />
                     <Text style={{marginLeft: '10px'}}>Anzahl der Char <br/></Text>
                </> )}
                          </Col>
                        </Row>
                </> )}
        </Card>
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={[16, 0]}>
        <Col span={16} className="custom-offset-dropdown">
        <Form.Item  name="slotTypes">
        <Card title="Slot Art">
          <Checkbox.Group onChange={handleSlotTypeChange}>
          <Row>
                   <Col span={24}>
            <Checkbox value='slotStandard'>Standard</Checkbox><br />
            <Checkbox value='slotBrieftasche'>Brieftasche</Checkbox><br />
            
            </Col>
                        </Row>
                        <Row>
                        <Col span={24}>
            <Checkbox value='slotAusruestung'>Ausrüstung</Checkbox><br />
            <Checkbox value='slotTasche'>Tasche</Checkbox><br />
            </Col>
                        </Row>
                        <Row>
                        <Col span={24}>
            <Checkbox value='slotKleidung'>Kleidung</Checkbox><br />
            </Col>
            </Row>
          </Checkbox.Group>
          </Card>
        </Form.Item>
        </Col>
        </Row>
    

     
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
            Anlegen
          </Button>
        </Form.Item>
     
      <Modal
              title={`X:`}
              open={visableX}
            onOk={() => setVisableX(false)}
            onCancel={() => setVisableX(false)}
        
          >  <div>
            <Form>
             <Form.Item name="mehrX" rules={[{ validator: validateX }]}>
                <Input type="number" style={{ width: '100%' }} onChange={(e) => setX(e.target.value)} />
                
                </Form.Item>
                </Form>
                </div>
          </Modal>
          <Modal
              title={`Y:`}
              open={visableY}
            onOk={() => setVisableY(false)}
            onCancel={() => setVisableY(false)}
        
          >  <div>
              <Form>
             <Form.Item name="mehrY" rules={[{ validator: validateY }]}>
                <Input type="number" style={{ width: '100%' }} onChange={(e) => setY(e.target.value)} />
                
                </Form.Item>
                </Form>
                </div>
                   
          </Modal>
          </Form>
    </Space>

    
  );
};

export default ItemsAnlegen;